import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SharedService } from '../../../../core/shared/shared.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-account-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountOverviewComponent implements OnInit, AfterViewInit {
  private id: any;
  private errorSubmit: boolean = false;
  private isSubmitting: boolean = false;
  private isCompanyInvite: any;
  private userData: any;
  private urlConfigElements = {
    elIncompleted: true
  }

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private authService: AuthService,
    private loader: LoaderService
  ) { }
    

  ngOnInit() {
    const url = this.router.url.split('/');
    const lastSegment = this.getLastUrlSegment(url);
    this.isCompanyInvite = this.route.snapshot.queryParamMap.get('isCompanyInvite');

    // pensar em uma solução genérica
    lastSegment === "incompleted" ? 
      this.urlConfigElements.elIncompleted = true : 
      this.urlConfigElements.elIncompleted = false;

    // this.authService.acceptCompanyInvite(companyInviteKey)
    //   .subscribe(
    //     data => console.log(data),
    //     error => console.log(error)
    //   );
    //  
  }

  ngAfterViewInit() {
    this.authService.getCurrentUser()
      .subscribe(
        data => {
          this.userData = data; console.log(data);
          this.loader.hide();
        },
        error => { 
          console.log(error);
        }
      );
  }


  getLastUrlSegment(route): string {
    var parts = this.router.url.split('/');
    var lastSegment = parts.pop() || parts.pop();
    
    return lastSegment;
  }
}
