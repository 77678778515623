import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'company-phones',
  templateUrl: './phones.component.html',
  styleUrls: ['./phones.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'contactCompanyPhonesModal'}
})
export class ContactCompanyPhonesModalComponent implements OnInit {
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onAddPhoneCompanyEmit = new EventEmitter<any>();
  @Input() modalPhonesCompanyOpen: boolean;
  @Input("companyPhones") companyPhones: any;

  private phoneInstitutionIdForm: FormGroup;
  private institutionPhoneForm: FormGroup;
  private selectedPhoneId: number;
  private modalWrapper: any;
  
  constructor(
    private formBuilder: FormBuilder) {}

  ngOnInit() { 
    this.modalWrapper = document.getElementById('contactCompanyPhonesModal') as HTMLElement;
    this.modalWrapper.addEventListener('click', (e) => {
      this.onModalClose();
    });

    this.phoneInstitutionIdForm = this.formBuilder.group({
      phoneInstitution: [ '' ]
    })

    this.institutionPhoneForm = this.formBuilder.group({
      id: [ '' ],
      label: [ '' ],
      postalCode: [ '' ],
      line1: [ '' ],
      line2: [ '' ],
      line3: [ '' ],
      cityName: [ '' ],
      countryName: [ '' ],
      stateName: [ '' ]
    });

    console.log(this.companyPhones)

    // cityId: 3701
    // cityName: "Altamira do Paraná"
    // countryCode: "BR"
    // countryName: "Brasil"
    // id: 26
    // label: "Residencia"
    // line1: "Avenida Salvadores Amados"
    // line2: "Ap. 282"
    // line3: ""
    // main: true
    // postalCode: "80060600"
    // stateCode: "18"
    // stateName: "Paraná"
  }

  public modalStopPropagation( $event ) {
    $event.stopPropagation();
  }

  public onModalClose() {
    this.onClose.emit( !this.modalPhonesCompanyOpen );
  }

  private convertFormValues( form ) {
    let formValues = form.value;
    
    return {
      "id": formValues.id,
      "label": formValues.label,
      "postalCode": formValues.postalCode,
      "line1": formValues.line1,
      "line2": formValues.line2,
      "line3": formValues.line3,
      "cityName": formValues.cityName,
      "countryName": formValues.countryName,
      "stateName": formValues.stateName
    }
  }

  public onPhoneChange( phone: number ): void {
    this.selectedPhoneId = phone;
  }


  public onSubmitCompanyPhone() {
    let selectedPhone;
    
    if( this.phoneInstitutionIdForm.invalid ) { return }
    
    selectedPhone = this.companyPhones.filter( el => {
      return el.id == this.phoneInstitutionIdForm.value.phoneInstitution;
    })[0];

    console.log(selectedPhone)

    if( selectedPhone ) {
      this.institutionPhoneForm.patchValue({
        id: selectedPhone.id,
        label: selectedPhone.label,
        postalCode: selectedPhone.postalCode,
        line1: selectedPhone.line1,
        line2: selectedPhone.line2,
        line3: selectedPhone.line3,
        cityName: selectedPhone.cityName,
        countryName: selectedPhone.countryName,
        stateName: selectedPhone.stateName
      });

      const formValues = this.convertFormValues( this.institutionPhoneForm );

      this.onAddPhoneCompanyEmit.emit( formValues );

      setTimeout(() => {
        this.onModalClose();
      }, 1000);
    }
  }
}
