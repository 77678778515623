import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../_services/loader.service';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    private requestCount: number = 0;

    constructor(public loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // array in local storage for registered users
        let users: any[] = JSON.parse(localStorage.getItem('users')) || [];

        // this.loaderService.show();

        // next.handle(request).pipe(
        //     finalize(() => this.loaderService.hide())
        // );

        this.requestCount++;
        this.loaderService.show();

        /*
         *  Notificações
         */

        // Sucesso - criação de Conta Corporativa
        // if (request.url.endsWith('/api/company') && request.method === 'POST') {
        //     return next.handle(request).pipe(
        //         tap((event: HttpEvent<any>) => {
        //             if (event instanceof HttpResponse && event.status === 201) {
        //                 alert()
        //             }
        //         })
        //     );
        // }
        
        /*
         *  Console stuff
         */
        console.group('%c -= Requests Monitoring =-', 'color: white; background-color: #09477f');
        console.log( request );
        // console.table( request );
        console.groupEnd();
        

        return next.handle(request).pipe(
            finalize(() => {
                this.requestCount--;

                if (this.requestCount === 0) {
                    this.loaderService.hide();
                }
            })
        );


        // const httpsReq = request.clone({
        //     url: request.url.replace("http://", "https://")
        // });

        // return next.handle(httpsReq);


        // authenticate
            // if (request.url.endsWith('/users/authenticate') && request.method === 'POST') {
            //     // find if any user matches login credentials
            //     let filteredUsers = users.filter(user => {
            //         return user.username === request.body.username && user.password === request.body.password;
            //     });

            //     if (filteredUsers.length) {
            //         // if login details are valid return 200 OK with user details and fake jwt token
            //         let user = filteredUsers[0];
            //         let body = {
            //             id: user.id,
            //             username: user.username,
            //             firstName: user.firstName,
            //             lastName: user.lastName,
            //             token: 'fake-jwt-token'
            //         };

            //         return of(new HttpResponse({ status: 200, body: body }));
            //     } else {
            //         // else return 400 bad request
            //         return throwError({ error: { message: 'Username or password is incorrect' } });
            //     }
            // }


        // wrap in delayed observable to simulate server api call
        return of(null).pipe(mergeMap(() => {

                // authenticate
                // if (request.url.endsWith('/users/authenticate') && request.method === 'POST') {
                //     // find if any user matches login credentials
                //     let filteredUsers = users.filter(user => {
                //         return user.username === request.body.username && user.password === request.body.password;
                //     });

                //     if (filteredUsers.length) {
                //         // if login details are valid return 200 OK with user details and fake jwt token
                //         let user = filteredUsers[0];
                //         let body = {
                //             id: user.id,
                //             username: user.username,
                //             firstName: user.firstName,
                //             lastName: user.lastName,
                //             token: 'fake-jwt-token'
                //         };

                //         return of(new HttpResponse({ status: 200, body: body }));
                //     } else {
                //         // else return 400 bad request
                //         return throwError({ error: { message: 'Username or password is incorrect' } });
                //     }
                // }

                // get users
                if (request.url.endsWith('/api/users') && request.method === 'GET') {
                    // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
                    // if (request.headers.get('Authorization') === 'Bearer fake-jwt-token') {
                    //     return of(new HttpResponse({ status: 200, body: users }));
                    // } else {
                    //     // return 401 not authorised if token is null or invalid
                    //     return throwError({ status: 401, error: { message: 'Unauthorised' } });
                    // }
                    // return of(new HttpResponse({ status: 200, body: request.body }));
                    console.log('tssgfgsdasfa')
                    return of(new HttpResponse({ status: 200, body: request.body }));

                    // alert();
                }

                // get user by id
                // if (request.url.match(/\/users\/\d+$/) && request.method === 'GET') {
                //     // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                //     if (request.headers.get('Authorization') === 'Bearer fake-jwt-token') {
                //         // find user by id in users array
                //         let urlParts = request.url.split('/');
                //         let id = parseInt(urlParts[urlParts.length - 1]);
                //         let matchedUsers = users.filter(user => { return user.id === id; });
                //         let user = matchedUsers.length ? matchedUsers[0] : null;

                //         return of(new HttpResponse({ status: 200, body: user }));
                //     } else {
                //         // return 401 not authorised if token is null or invalid
                //         return throwError({ status: 401, error: { message: 'Unauthorised' } });
                //     }
                // }

                // register user
                // if (request.url.endsWith('/users/register') && request.method === 'POST') {
                //     // get new user object from post body
                //     let newUser = request.body;

                //     // validation
                //     let duplicateUser = users.filter(user => { return user.username === newUser.username; }).length;
                //     if (duplicateUser) {
                //         return throwError({ error: { message: 'Username "' + newUser.username + '" is already taken' } });
                //     }

                //     // save new user
                //     newUser.id = users.length + 1;
                //     users.push(newUser);
                //     localStorage.setItem('users', JSON.stringify(users));

                //     // respond 200 OK
                //     return of(new HttpResponse({ status: 200 }));
                // }

                // delete user
                // if (request.url.match(/\/users\/\d+$/) && request.method === 'DELETE') {
                //     // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                //     if (request.headers.get('Authorization') === 'Bearer fake-jwt-token') {
                //         // find user by id in users array
                //         let urlParts = request.url.split('/');
                //         let id = parseInt(urlParts[urlParts.length - 1]);
                //         for (let i = 0; i < users.length; i++) {
                //             let user = users[i];
                //             if (user.id === id) {
                //                 // delete user
                //                 users.splice(i, 1);
                //                 localStorage.setItem('users', JSON.stringify(users));
                //                 break;
                //             }
                //         }

                //         // respond 200 OK
                //         return of(new HttpResponse({ status: 200 }));
                //     } else {
                //         // return 401 not authorised if token is null or invalid
                //         return throwError({ status: 401, error: { message: 'Unauthorised' } });
                //     }
                // }

                // pass through any requests not handled above
                return next.handle(request);

            }))

            // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(materialize())
            .pipe(delay(500))
            .pipe(dematerialize());
    }
}

export let fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};