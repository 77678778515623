import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-font-scale',
  templateUrl: './font-scale.component.html',
  styleUrls: ['./font-scale.component.scss']
})
export class FontScaleComponent implements OnInit {
  private fontSize: number = 100;
  private mobileSizeParameter: number;
  private isMobile: boolean = false;
  public element = document.getElementsByTagName("html")[0];
  public innerWidth: any;
  public representation: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    
    this.checkIsMobile();
  }
  
  constructor() { }
  
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.checkIsMobile();
  }

  checkIsMobile() {
    if ( this.innerWidth < 1000 ) {
      this.isMobile = true; 
      this.fontSize = 87.5;
      
    } else {
      this.isMobile = false;
      this.fontSize = 100;
      
    }

    this.isMobile ? this.representation = "Mobile" : this.representation = "Web";
    this.mobileSizeParameter = this.fontSize * 0.10;
    this.element.style.fontSize = `${this.fontSize}%`;
  }

  onSizeChange(event: string): void {
    if ( event === "+") {
      if ( this.isMobile ) {
        this.fontSize += this.mobileSizeParameter;

      } else {
        this.fontSize += 10;
      }
      
    } else {
      if ( this.isMobile ) {
        this.fontSize -= this.mobileSizeParameter;

      } else {
        this.fontSize -= 10;

      }
    }
    
    this.element.style.fontSize = `${this.fontSize}%`;
  }
}
