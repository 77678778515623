import { Component, OnInit, Output, EventEmitter, HostListener, AfterViewInit, Input } from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @Output() onToggle = new EventEmitter<boolean>();
  @Input() selectedEvent: any;

  // for debugging purposes
  @HostListener("window:resize", ["$event"])
    onResize(event) {
      // this.checkResponsive();
    }

  public sidebarHidden: boolean = false;
  public isMobile: boolean = false;

  constructor() { }

  ngOnInit() {
    screen.width < 1020 ? 
      this.isMobile = true : 
      this.isMobile = false
  }

  ngAfterViewInit() {
    this.checkResponsive();
  }


  private checkResponsive() {
    if ( screen.width < 1200 && !this.sidebarHidden ) {
      this.sidebarToggle();
    }
  }


  private onModalToggle(): void {
    this.onToggle.emit( this.sidebarHidden );
  }


  private sidebarToggle( isResponsive?: boolean ): void {
    let appSidebar = document.getElementById("appAside") as HTMLElement;
    let menuItems = document.querySelectorAll("#appAside .aside__menu");

    // Sidebar aberto
    if ( !this.sidebarHidden ) {
      menuItems.forEach( el => {
        el.classList.remove("menu__open");
      })

      if ( isResponsive || this.isMobile ) {
        appSidebar.classList.remove("mobile-open");
        appSidebar.classList.add("mobile-hide");

      } else {
        appSidebar.classList.add("sidebar-hide");
      }

    // Sidebar fechado
    } else {
      if ( isResponsive || this.isMobile ) {
        appSidebar.classList.remove("mobile-hide");
        appSidebar.classList.add("mobile-open");

      } else {
        appSidebar.classList.remove("sidebar-hide");
      }
    }

    this.sidebarHidden = !this.sidebarHidden;
    this.onModalToggle();
  }


  public onSidebarToggle( isResponsive?: boolean ): void {
    this.sidebarToggle(isResponsive);
  }


  public asideMenuToggle(event): void {
    !event.classList.contains("menu__open") ?
      event.classList.add("menu__open") : 
      event.classList.toggle("menu__open");
    
    if ( this.sidebarHidden )
      this.sidebarToggle();
  
  }
}
