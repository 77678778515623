import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-team-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamPermissionsComponent implements OnInit {
  private isMemberPermissionEdit: boolean = false;
  private isPresetPermissionEdit: boolean = false;
  private isJobRolePermissionEdit: boolean = false;
  private isJobRolePermissionNew: boolean = false;
  private optionSelected: string = '';
  private userId: string;
  private memberData: any;

  private addTeamOpen: boolean = false;
  
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    if ( this.route.snapshot.paramMap.get('format') )
      this.optionSelected = this.route.snapshot.paramMap.get('format');
    
    if ( this.route.snapshot.paramMap.get('id') )
      this.userId = this.route.snapshot.paramMap.get('id');

    this.configPermissionSettings(this.optionSelected);

    // data sent from the member through routerLink
    this.memberData = history.state.data;

  }

  configPermissionSettings = (option: string) => {
    if ( option === 'jobrole-edit' ) {
      this.isJobRolePermissionEdit = true;
    
    } else if ( option === 'jobrole-new' ) {
      this.isJobRolePermissionNew = true;

    } else if ( option === 'member-edit' ) {
      this.isMemberPermissionEdit = true;

    } else if ( option === 'preset-edit' ) {
      this.isPresetPermissionEdit = true;
    }  
  }

  onAddClick = () => {
    this.addTeamOpen = true;
  }

  onModalClose = (val: boolean) => {
    this.addTeamOpen = val;
  }

  removeMember = ($event) => {
    console.log($event);
  }
}
