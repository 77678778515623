import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-message',
  templateUrl: './account-message.component.html',
  styleUrls: ['./account-message.component.scss'],
})
export class AccountMessageComponent implements OnInit, AfterViewInit {
  private messageFormat: string;
  private format: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    /*
      Centralizar feedback de mensagens ao usuário neste componente */
    this.messageFormat = this.route.snapshot.queryParamMap.get('messageFormat');
  }

  ngAfterViewInit() {
    if ( this.messageFormat === 'company-invite' ) {
      this.format = 'conta-vinculada';

    } else {
      // Default para voltar à tela de overview
      this.router.navigate(['/client/account/overview']);
    }
  }
}
