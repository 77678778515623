import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
    private sharedSubjectCorporateUser = new BehaviorSubject<any>('');
    observeCorporateUser: Observable<any>;

    constructor() {
        this.observeCorporateUser = this.sharedSubjectCorporateUser.asObservable();
    }

    setCorporateUser( data: any ) {
        this.sharedSubjectCorporateUser.next(data);
    }
}
