import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user.model';

// import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthService {
  httpHeaderOptions() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this.getToken("id_token")}`
    });

    return headers;
  }

  constructor(
    private httpClient: HttpClient, 
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
    
  getToken(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  }

  
  /*
    GENERAL REQUESTS
  */

  getCountries() {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/address/countries`, { headers: headers, withCredentials: true });
  }

  getStatesByCode(code) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/address/states`, { headers: headers, withCredentials: true, params: { 'countryCode': code } })
      .pipe( map(
        states => { return states },
        error => { console.log(error); }
      ));
  }

  getCitiesByCode(stateCode, countryCode) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/address/cities`, { headers: headers , withCredentials: true, params: { 'countryCode': countryCode, 'stateCode': stateCode }})
      .pipe( map(
        cities => { return cities },
        error => { console.log(error); }
      ));
  }



  /*
      USERS and ACCOUNTS REQUESTS
  */
  getCurrentUser() {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .get<User>(`${environment.apiUrl}/api/user/currentuser`, { headers: headers, withCredentials: true });
  }

  updateCurrentUser(formData) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .put<any>(`${environment.apiUrl}/api/user`, formData, { headers: headers, withCredentials: true });
  }

  registerAccount(formData) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/user`, formData, { headers: headers });
  }

  activateAccount(key) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/activate`, key, { headers: headers });
  }

  recoverAccount(formData) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/account/reset-password/init`, formData, { headers: headers, withCredentials: true })
      .pipe(map(
        user => { return user },
        error => { console.log(error); }
      ));
  }

  recoverAccountNewPassword(password, key) {
    const headers = this.httpHeaderOptions();
    let data = {};

    data["key"] = key;
    data["newPassword"] = password;

    console.log(data)

    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/account/reset-password/finish`, data, { headers: headers, withCredentials: true })
      .pipe( map(
        user => { return user },
        error => { console.log(error); }
      ));
  }

  userLogin(formData) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/login`, formData, { headers: headers, withCredentials: true })
      .pipe( map(
        user => {
          // login successful if there's a jwt token in the response
          if ( user && user.id_token ) {
            document.cookie = `id_token=${user.id_token};`;


          // if ( user && ( user.access_token || user.refresh_token )) {
            // document.cookie = `access_token=${user.access_token};`;
            // document.cookie = `refresh_token=${user.refresh_token};`;
            // document.cookie = `expires_in=${user.expires_in};`;
          }
          return user;
        },

        error => { console.log(error); }
      ));
  }

  userLogout() {
    const headers = this.httpHeaderOptions();
    localStorage.removeItem('accountType');

    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    
    this.cookieService.deleteAll('/');
    this.cookieService.deleteAll('/auth');
    // this.router.navigate(['../../auth'], { relativeTo: this.route });
    this.router.navigate(['/auth']);
  }




  /*
      COMPANY REQUESTS
  */
  createCompany(formData) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/company`, formData, { headers: headers, withCredentials: true });
  }

  updateCompany( formData ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .put<any>(`${environment.apiUrl}/api/company`, formData, { headers: headers, withCredentials: true });
  }

  recoverCompanyAccount(formData) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/company/request/send`, formData, { headers: headers, withCredentials: true })
      .pipe(map(
        user => { return user },
        error => { console.log(error); }
      ));
  }

  getCompaniesAll() {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/company?sort=name`, { headers: headers, withCredentials: true });
  }

  getCompaniesById( id ): Observable<any> {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/company/${id}`, { headers: headers, withCredentials: true });
  }

  //    PERSONS REQUESTS
  createCompanyContactPerson( formData, companyId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/company/${companyId}/contact/person`, formData, { headers: headers, withCredentials: true })
      .pipe(map(
        user => { return user },
        error => { console.log(error); }
      ));
  }

  getCompanyContactPerson( companyId, personId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/company/${companyId}/contact/person/${personId}`, { headers: headers, withCredentials: true });
  }

  getCompanyContactsPerson( companyId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/company/${companyId}/contact/person`, { headers: headers, withCredentials: true });
  }

  updateCompanyContactPerson( formData, companyId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .put<any>(`${environment.apiUrl}/api/company/${companyId}/contact/person`, formData, { headers: headers, withCredentials: true });
  }

  createRelationshipContactCompany( formData, companyId, personId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
    // {{url}}/api/company/2/contact/person/3/institution
      .post<any>(`${environment.apiUrl}/api/company/${companyId}/contact/person/${personId}/institution`, formData, { headers: headers, withCredentials: true })
      .pipe(map(
        user => { return user },
        error => { console.log(error); }
      ));
  }

  getRelationshipContactCompany( companyId, personId, institutionId) {
    const headers = this.httpHeaderOptions();

    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/company/${companyId}/person/${personId}/instituition/${institutionId}`, { headers: headers, withCredentials: true });
  }


  //    INSTITUTION REQUESTS
  createCompanyContactInstitution( formData, companyId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/company/${companyId}/contact/institution`, formData, { headers: headers, withCredentials: true })
      .pipe(map(
        user => { return user },
        error => { console.log(error); }
      ));
  }

  getCompanyContactInstitution( companyId, institutionId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/company/${companyId}/contact/institution/${institutionId}`, { headers: headers, withCredentials: true });
  }

  updateCompanyContactInstitution( formData, companyId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .put<any>(`${environment.apiUrl}/api/company/${companyId}/contact/institution`, formData, { headers: headers, withCredentials: true });
  }

  getCompanyContactsInstitution( companyId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .get<any>(`${environment.apiUrl}/api/company/${companyId}/contact/institution`, { headers: headers, withCredentials: true });
  }

  acceptCompanyInvite( inviteKey ) {
    const headers = this.httpHeaderOptions();
    const params = new HttpParams()
      .set('key', inviteKey);
    
    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/company/invite/accept`, inviteKey, { params: params, headers: headers, withCredentials: true });
  }

  

  /*
      TEAMS REQUESTS
  */
  requestAddMember( members, companyId ) {
    const headers = this.httpHeaderOptions();
    
    return this.httpClient
      .post<any>(`${environment.apiUrl}/api/company/${companyId}/invite/send`, members, { headers: headers, withCredentials: true });
  }
}




