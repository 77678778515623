import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'company-contact-comments',
  templateUrl: './contact-comments.component.html',
  styleUrls: ['./contact-comments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appCommentsWrapper'}
})
export class CompanyContactCommentsComponent implements OnInit {
  @Output() onCloseComments = new EventEmitter<boolean>();
  private showComments: boolean = true;

  // public commentsList = [
  //   {
  //     "id": 0,
  //     "date": "16/04/2018",
  //     "name": "Frye Greer",
  //     "comment": "Commodo pariatur aliquip cillum duis. Consequat adipisicing eiusmod sint quis ut fugiat. Labore velit aliqua eu qui consequat nulla proident. Sunt ullamco incididunt mollit veniam duis dolore reprehenderit.\r\n"
  //   },
  //   {
  //     "id": 1,
  //     "date": "22/05/2018",
  //     "name": "Rush Clayton",
  //     "comment": "Duis aute consectetur sunt dolor amet labore eiusmod tempor labore qui excepteur. Nulla laborum reprehenderit sint cillum elit aute ea ipsum aliqua ullamco. Veniam laborum do aliquip anim ad ullamco consequat.\r\n"
  //   },
  //   {
  //     "id": 2,
  //     "date": "03/07/2018",
  //     "name": "Delaney Rocha",
  //     "comment": "Eiusmod incididunt ad voluptate tempor sint fugiat proident tempor Lorem sunt nisi sint nulla. Minim culpa labore eiusmod esse est culpa consectetur. Non adipisicing sunt fugiat ex laborum. Nulla ex id laboris velit magna officia. Sint aliqua aliqua voluptate cupidatat aliquip eiusmod duis cupidatat minim dolor voluptate mollit.\r\n"
  //   },
  //   {
  //     "id": 3,
  //     "date": "07/10/2018",
  //     "name": "Poole Summers",
  //     "comment": "Magna fugiat ex aute ex enim ex exercitation est laboris irure nostrud eiusmod anim mollit. Velit qui deserunt anim consectetur culpa amet magna. Duis laboris do consectetur anim deserunt non voluptate. Laboris ut minim reprehenderit ad qui occaecat adipisicing irure voluptate cillum eu aute labore. Excepteur do voluptate velit non veniam aliquip labore anim dolore elit ex ut officia occaecat. Ipsum et non fugiat ut qui non ut. Reprehenderit pariatur sit do adipisicing amet amet aliquip.\r\n"
  //   },
  //   {
  //     "id": 4,
  //     "date": "31/07/2014",
  //     "name": "Edith Stewart",
  //     "comment": "Labore fugiat mollit dolor nisi aute enim cillum consectetur. Sit nulla fugiat dolor voluptate. Enim anim cillum labore laboris cillum qui incididunt enim quis proident mollit. Laborum proident ea minim dolor adipisicing laboris proident voluptate excepteur mollit dolor cupidatat culpa. Reprehenderit commodo eiusmod eiusmod ut minim aliquip incididunt non ullamco pariatur. Ea labore adipisicing laboris sit consectetur est laborum. Sint mollit officia minim magna amet ea.\r\n"
  //   },
  //   {
  //     "id": 5,
  //     "date": "29/07/2017",
  //     "name": "Althea Weaver",
  //     "comment": "Deserunt consequat exercitation aliqua reprehenderit enim. Ad consequat eiusmod cupidatat aute velit sunt eiusmod sint. Est pariatur commodo cupidatat elit nulla tempor commodo sunt enim. Consectetur elit amet pariatur occaecat in amet quis proident culpa.\r\n"
  //   },
  //   {
  //     "id": 6,
  //     "date": "10/09/2015",
  //     "name": "Alberta Mcneil",
  //     "comment": "Cillum ipsum tempor aliqua esse sunt voluptate occaecat dolor tempor commodo. Tempor eiusmod veniam consectetur magna enim qui veniam consequat ex nisi. Incididunt officia adipisicing sint excepteur magna aute officia fugiat nisi. Adipisicing dolor sunt officia enim officia deserunt consectetur commodo pariatur. Irure sunt cupidatat nulla veniam ex excepteur dolore nostrud esse magna reprehenderit. Nostrud excepteur esse dolor nulla aliquip magna. Est veniam do do aute.\r\n"
  //   },
  //   {
  //     "id": 7,
  //     "date": "31/12/2018",
  //     "name": "Anne Paul",
  //     "comment": "Adipisicing laborum consectetur nulla aute ea et adipisicing sunt nulla aute laborum proident. Culpa anim laboris aute esse ipsum ut duis veniam. Veniam ipsum quis deserunt et ad anim nulla exercitation aute.\r\n"
  //   },
  //   {
  //     "id": 8,
  //     "date": "22/03/2014",
  //     "name": "Anastasia Todd",
  //     "comment": "Anim officia eu quis labore in. Et id irure veniam veniam excepteur pariatur incididunt quis voluptate. Dolor tempor mollit irure nulla non nostrud aliqua fugiat labore. Lorem nulla eiusmod cillum sit aliqua duis dolore adipisicing Lorem id laborum dolor. Laborum consectetur tempor velit Lorem nisi.\r\n"
  //   },
  //   {
  //     "id": 9,
  //     "date": "30/12/2018",
  //     "name": "Angelina Chang",
  //     "comment": "Amet laboris dolore amet deserunt culpa veniam do pariatur ullamco ipsum. Enim excepteur eiusmod in labore. Deserunt adipisicing excepteur nostrud ut ullamco aliquip aliquip elit veniam exercitation pariatur ullamco.\r\n"
  //   },
  //   {
  //     "id": 10,
  //     "date": "26/05/2014",
  //     "name": "Elva Owens",
  //     "comment": "Excepteur do veniam cillum reprehenderit consequat sit. Nisi aute ut amet esse tempor excepteur incididunt anim nulla occaecat. Cupidatat consectetur est non nisi officia velit dolore adipisicing officia cillum labore. Irure ex nostrud laboris qui deserunt laborum commodo culpa ut non.\r\n"
  //   },
  //   {
  //     "id": 11,
  //     "date": "01/06/2018",
  //     "name": "Marguerite Villarreal",
  //     "comment": "Lorem aliquip minim ut et ut consequat ipsum mollit pariatur dolore proident cillum consectetur est. Aliqua ullamco amet nulla dolore occaecat aliqua cupidatat ullamco ea. Dolor minim cillum irure enim.\r\n"
  //   },
  //   {
  //     "id": 12,
  //     "date": "12/02/2018",
  //     "name": "Carrillo Acevedo",
  //     "comment": "Et irure minim aliquip aute exercitation anim incididunt cillum. Magna do duis consectetur est officia. Sit occaecat aliqua duis id. Dolore officia esse non elit culpa ut nostrud id nostrud eiusmod excepteur consequat est reprehenderit. Velit mollit amet ut cillum commodo. Non fugiat fugiat tempor nostrud duis non ex enim consectetur incididunt proident excepteur.\r\n"
  //   },
  //   {
  //     "id": 13,
  //     "date": "11/09/2014",
  //     "name": "Benton Wise",
  //     "comment": "Sunt laboris ut pariatur aute irure fugiat consectetur. Deserunt tempor pariatur ipsum incididunt qui officia id nisi eu do consequat consectetur. Laborum adipisicing ad aliqua sint occaecat. Ea excepteur non aliqua irure irure nulla proident eu.\r\n"
  //   },
  //   {
  //     "id": 14,
  //     "date": "27/10/2016",
  //     "name": "Blevins Peters",
  //     "comment": "Aliquip occaecat elit ex aliqua occaecat Lorem consequat consectetur exercitation amet aute tempor. Minim exercitation tempor irure aute. Lorem deserunt et proident culpa nostrud proident tempor nisi in.\r\n"
  //   }
  // ];

  public commentList = [];

  constructor() { }

  ngOnInit() {
  }
  
  closeComments() { 
    this.onCloseComments.emit(false);
  }
}
