import { Component, OnInit, ElementRef, Input, HostListener, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { SharedService } from 'src/app/core/shared/shared.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { AccountService } from 'src/app/_services/account/account.service';
import { CompanyService } from 'src/app/_services/company/company.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private currentUser: any;
  private accountSubs: Subscription;
  private accountType: any = '';
  private corpAccountsList;
  private selectedCompanyId: string;
  public mobileSizeParameter: number;
  public fontSize: number = 100;
  public isMobile: boolean = false;
  public isSidebarOpen: boolean = false;
  public menuOpen: boolean = false;
  public userLoading: boolean = false;
  public htmlTag = document.getElementsByTagName("html")[0];

  @Output() emitToggleSidebar = new EventEmitter();

  @Input() eveLogoResponsive: boolean;
  @Input() selectedEvent: any;

  @HostListener('document:keydown.escape', ['$event']) 
    onKeydownHandler(event: KeyboardEvent) {
        this.menuOpen = false;
  }

  @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      // this.checkIsMobile();
      this.toggleDomResponsive()
    }

  @HostListener('document:click', ['$event.target']) 
    onClick(target) {
      const userMenu = document.getElementById('userMenuDropdown');
      let isUserMenu: boolean = false;
      let event = target;

      if ( event.id !== 'user-wrapper') {
        do {
          if (event == userMenu) { isUserMenu = true; }
          event = event.parentNode;

        } while (event);
  
        if ( !isUserMenu && this.menuOpen ) { this.menuOpen = false; }
      }
    }


  constructor (
    private router: Router,
    private elementRef: ElementRef, 
    private sharedService: SharedService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private companyService: CompanyService
  ) {}

  
  ngOnInit() {
    this.selectedCompanyId = localStorage.getItem('selected-company-id');

    if ( !this.selectedCompanyId ) {
      this.companyService.observeCorporateUser
        .subscribe( el => this.selectedCompanyId = el );
    }

    this.router.events.subscribe( ev => {
      if( ev instanceof NavigationEnd ) {
        this.setupAccountInstance(this.router.url);
        this.menuOpen = false;
      }
    });

    if ( this.currentUser === undefined ) {
      this.userLoading = true;

      this.authService.getCurrentUser()
        .subscribe(
          data => {
            this.currentUser = data;
          },
          error => { console.log(error) },
          () => { this.userLoading = false; }
        );
    }
    
    this.authService.getCompaniesAll()
      .subscribe(
        data => { this.corpAccountsList = data },
        error => { console.log(error) }
      );

    this.checkIsMobile();
    this.toggleDomResponsive()
  }


  ngAfterViewInit() {
    this.setupAccountInstance(this.router.url);
    this.toggleDomResponsive();
  }

  // private onSearch(search: string):void {
  //   if ( search.length > 0 ) {
  //     console.log(search);
  //   } else {
  //     console.log('nothing');
  //   }
  // }

  // public onKeypress(e) {
  //   if ( e.keyCode === 13 || e.charCode === 13 || e.key === "Enter") {
  //     const query = this.elementRef.nativeElement.querySelector('#searchInput').value;

  //     this.onSearch(query);
  //   }
  // }

  public onNotAllowed() {
    alert("Em construção!");
  }

  public sidebarToggle() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.emitToggleSidebar.emit(this.isSidebarOpen);
  }


  public toggleDomResponsive() {
    const windowWidth = window.innerWidth;
    let items = document.querySelectorAll<HTMLElement>("#headerMenu .menu-item");

    if ( windowWidth < 1200 ) {
      items.forEach( el => { el.style.display = 'none'; })
    } else {
      items.forEach( el => { el.style.display = 'inline-block'; })
    }
  }

  public onMenuOpen() {
    // this.emitToggleSidebar.emit(false);
    this.menuOpen = !this.menuOpen;
  }

  public accountInfo(): void {
    localStorage.setItem('accountType', 'company');
  }


  private setupAccountInstance( url: string ) {
    if( url ) {
      const urlFragments = url.split('/');
      let urlAccountType = urlFragments[2];

      console.log(urlAccountType)
      
      if( urlAccountType === 'company' ) {
        this.setUserType('company');
        localStorage.setItem( 'accountType', 'company' );

      } else {
        this.setUserType('user');
        localStorage.setItem( 'accountType', 'user' );

      }

      this.accountType = localStorage.getItem('accountType');
    }
  }


  private setUserType = ( type: string ): void => {
    if ( type === 'company' ) {
      document.documentElement.style.setProperty('--main-color', '#1E2324');
      document.documentElement.style.setProperty('--navbar-top-text', '#979797');

    } else {
      document.documentElement.style.setProperty('--main-color', '#0D959F');
      document.documentElement.style.setProperty('--navbar-top-text', '#FFFFFF');
    }
  }


  private checkIsMobile(): void {
    if ( window.innerWidth < 1020 ) {
      this.isMobile = true; 
      this.fontSize = 87.5;

    } else {
      this.isMobile = false;
      this.fontSize = 100;
    }

    this.mobileSizeParameter = this.fontSize * 0.10;
    this.htmlTag.style.fontSize = `${this.fontSize}%`;
  }


  public onSizeChange(event: string): void {
    if ( event === "+") {
      if ( this.isMobile ) {
        this.fontSize += this.mobileSizeParameter;

      } else { this.fontSize += 10; }
      
    } else {
      if ( this.isMobile ) {
        this.fontSize -= this.mobileSizeParameter;

      } else { this.fontSize -= 10; }
    }
    
    this.htmlTag.style.fontSize = `${this.fontSize}%`;
  }


  public onAccountChange( type, companyId: string = undefined ) {
    this.accountService.accountType.next( type );

    if( companyId ) {
      localStorage.setItem('selected-company-id' , companyId );
      this.companyService.setCorporateUser( companyId );
    }
  }


  public onLogout() {
    // this.accountSubs.unsubscribe();
    // setTimeout(() => localStorage.removeItem('accountType'), 20)
    
    this.onAccountChange('user');
    this.authService.userLogout();
  }
}
