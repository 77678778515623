import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxMaskModule, IConfig } from 'ngx-mask-2';
import { AccountService } from '../../_services/account/account.service';
import { CompanyService } from '../../_services/company/company.service';
import { ClientComponent } from './client.component';
import { SidebarComponent } from 'src/app/core/layout/sidebar/sidebar.component';
import { HeaderComponent } from 'src/app/core/layout/header/header.component';
import { AccountOverviewComponent } from './account/account-overview/overview.component';
import { CompanyComponent } from './company/company.component';
import { CompanyCreateComponent } from './account/company-create/create.component';
import { CompanyOverviewComponent } from './company/company-overview/overview.component';
import { CompanyEditComponent } from './company/company-edit/edit.component';
import { CompanyExistsComponent } from './account/company-create/company-exists/exists.component';
import { CompanyRecoverComponent } from './account/company-create/company-recover/recover.component';
import { OwnerAddComponent } from './company/company-overview/owner-add/add.component';
import { TeamPermissionsComponent } from './company/team/team-permissions/permissions.component';
import { AccountContactComponent } from './account/account-contact/contact.component';
import { AccountContactsComponent } from './account/account-contact/contacts/contacts.component';
import { AccountContactDetailsComponent } from './account/account-contact/contact-details/contact-details.component';
import { AccountContactCommentsComponent } from './account/account-contact/contact-comments/contact-comments.component';
import { AccountContactMenuComponent } from './account/account-contact/contact-menu/contact-menu.component';
import { AccountContactEditComponent } from './account/account-contact/contact-edit/contact-edit.component';
import { AccountContactActionsComponent } from './account/account-contact/contact-actions/contact-actions.component';
import { AccountContactsListviewComponent } from './account/account-contact/contacts-listview/contacts-listview.component';
import { AccountMessageComponent } from './account/account-message/account-message.component';
import { CompanyContactComponent } from './company/company-contact/contact.component';
import { CompanyContactsComponent } from './company/company-contact/contacts/contacts.component';
import { CompanyContactDetailsComponent } from './company/company-contact/contact-details/contact-details.component';
import { CompanyContactCommentsComponent } from './company/company-contact/contact-comments/contact-comments.component';
import { CompanyContactMenuComponent } from './company/company-contact/contact-menu/contact-menu.component';
import { CompanyContactEditComponent } from './company/company-contact/contact-edit/contact-edit.component';
import { CompanyContactInstitutionEditComponent } from './company/company-contact/contact-institution-edit/contact-edit.component';
import { CompanyContactActionsComponent } from './company/company-contact/contact-actions/contact-actions.component';
import { CompanyContactsListviewComponent } from './company/company-contact/contacts-listview/contacts-listview.component';
import { CompanyContactEditActionsComponent } from './company/company-contact/contact-edit/contact-edit-actions/contact-actions.component';
import { ContactCompanyAddModalComponent } from './company/company-contact/contact-edit/contact-company-add/add.component';
import { ContactCompanyAddressesModalComponent } from './company/company-contact/contact-edit/contact-company-addresses/addresses.component';
import { ContactCompanyEmailsModalComponent } from './company/company-contact/contact-edit/contact-company-emails/emails.component';
import { ContactCompanyPhonesModalComponent } from './company/company-contact/contact-edit/contact-company-phones/phones.component';
import { ContactCompanyRelationshipAddModalComponent } from './company/company-contact/contact-edit/contact-company-relationship/relationship.component';
import { EventsComponent } from './events/events.component';
import { FontScaleComponent } from './test/font-scale/font-scale.component';
import { AgGridModule } from 'ag-grid-angular';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    ClientComponent, 
    SidebarComponent, 
    HeaderComponent, 
    AccountOverviewComponent,
    CompanyComponent,
    CompanyCreateComponent,
    CompanyExistsComponent,
    CompanyEditComponent,
    CompanyOverviewComponent,
    CompanyRecoverComponent,
    OwnerAddComponent,
    TeamPermissionsComponent,
    AccountContactComponent,
    AccountContactsComponent,
    AccountContactsListviewComponent,
    AccountContactDetailsComponent,
    AccountContactCommentsComponent,
    AccountContactMenuComponent,
    AccountContactEditComponent,
    AccountContactActionsComponent,
    AccountMessageComponent,
    CompanyContactComponent,
    CompanyContactsComponent,
    CompanyContactsListviewComponent,
    CompanyContactDetailsComponent,
    CompanyContactCommentsComponent,
    CompanyContactMenuComponent,
    CompanyContactEditComponent,
    CompanyContactInstitutionEditComponent,
    CompanyContactActionsComponent,
    CompanyContactEditActionsComponent,
    ContactCompanyAddModalComponent,
    ContactCompanyAddressesModalComponent,
    ContactCompanyRelationshipAddModalComponent,
    ContactCompanyEmailsModalComponent,
    ContactCompanyPhonesModalComponent,
    EventsComponent,
    FontScaleComponent
  ],
  imports: [
    CommonModule, 
    RouterModule, 
    FormsModule, 
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
    NgxDatatableModule,
    AgGridModule.withComponents([]),
  ],
  exports: [ClientComponent],
  providers: [
    DatePipe,
    AccountService,
    CompanyService
  ]
})
export class ClientModule {}
