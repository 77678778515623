import { Routes } from '@angular/router';
import { TeamPermissionsComponent } from './team-permissions/permissions.component';

export const TeamRoutes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   loadChildren: () => 
  //     import('./team-manage/manage.module').then(m => m.TeamManageModule)
  // },
  {
    path: 'permissions',
    component: TeamPermissionsComponent
  },
  {
    path: 'permissions/:format/:id',
    component: TeamPermissionsComponent
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./team.module').then(m => m.TeamModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./team.module').then(m => m.TeamModule),
  }
];

