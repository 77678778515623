import { Component, OnInit, ElementRef, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'contact-edit-actions',
  templateUrl: './contact-actions.component.html',
  styleUrls: ['./contact-actions.component.scss']
})
export class CompanyContactEditActionsComponent implements OnInit {
  @Output() contactFormatEmit: EventEmitter<string> = new EventEmitter();
  @Input('isProfessionalEnabled') isProfessionalEnabled: boolean = false;

  private contactFormat: string = 'person';

  constructor() { }

  ngOnInit() {
    this.contactFormatEmit.emit(this.contactFormat);
  }

  public changeDataFormat ( contactFormat: string ) {
    if ( contactFormat === 'person' || 
         contactFormat === 'professional' && this.isProfessionalEnabled ) {
      this.contactFormat = contactFormat;
      this.contactFormatEmit.emit(this.contactFormat);
    }
    else {
      alert("Primeiro, é necessário Salvar este cadastro para, em seguida, informar os seus Dados Profissionais")
    }
  }
}

