import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthService } from 'src/app/_services/auth/auth.service';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appClientWrapper'}
})
export class ClientComponent implements OnInit {
  private isSubmitting: boolean = false;
  private sidebarToggle: boolean;
  public isMobile: boolean = false;
  
  public selectedEventData = {
    id: 123,
    title: "Virtual Online Meetup: Connect By Sharing Your Favorite Art from MOMA",
    imageUrl: "../../../../assets/client/icons/event-logo.jpg"
  }

  public isLoading: Subject<boolean>;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService) {}

  ngOnInit() {
    
    // this.isLoading = this.loaderService.isLoading;
  }

  // getCurrentUser = () => {
  //   this.isSubmitting = true;

  //   return this.authService.getCurrentUser().subscribe(
  //     userData => { 
  //       this.isSubmitting = false;
  //       return userData;
  //     },
  //     error => { console.log(error) }
  //   );
  // }
    
  onSidebarToggle(hide: boolean): void {
    let appWrapper = document.getElementById('appClientWrapper') as HTMLElement;
    
    this.sidebarToggle = hide;

    screen.width < 1020 ? 
      this.isMobile = true : 
      this.isMobile = false;
  
    if ( this.sidebarToggle ) {
      appWrapper.style.gridTemplateColumns = this.isMobile ? "0px 1fr" : "65px 1fr";
      
    } else {
      appWrapper.style.gridTemplateColumns = this.isMobile ? "0px 1fr" : "320px 1fr";
      
    }
  }
}
