import { Routes } from '@angular/router';

import { AccountOverviewComponent } from './account-overview/overview.component';
import { AccountContactComponent } from './account-contact/contact.component';
import { AccountContactEditComponent } from './account-contact/contact-edit/contact-edit.component';
import { AccountMessageComponent } from './account-message/account-message.component';
import { EventsComponent } from '../events/events.component';
import { CompanyCreateComponent } from './company-create/create.component';

export const AccountRoutes: Routes = [
  {
    path: 'incompleted',
    component: AccountOverviewComponent
  },
  {
    path: 'fill',
    loadChildren: () =>
      import('./account-edit/edit.module').then(m => m.AccountEditModule)
  },
  {
    path: 'edit',
    loadChildren: () =>
      import('./account-edit/edit.module').then(m => m.AccountEditModule)
  },
  {
    path: 'overview',
    component: AccountOverviewComponent
  },
  {
    path: 'overview/:id',
    component: AccountOverviewComponent
  },
  {
    path: 'events',
    component: EventsComponent
  },
  {
    path: 'contact',
    component: AccountContactComponent
  },
  {
    path: 'contact/:contactId/edit',
    component: AccountContactEditComponent
  },
  {
    path: 'contact/new',
    component: AccountContactEditComponent
  },
  {
    path: 'message',
    component: AccountMessageComponent
  },
  {
    path: 'company-create',
    component: CompanyCreateComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: AccountOverviewComponent
  },
  {
    path: '**',
    component: AccountOverviewComponent,
  }
];

