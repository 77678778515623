import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'company-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appContactsWrapper'}
})
export class CompanyContactsComponent implements OnInit {
  @Output() contactSelectedEmit = new EventEmitter();
  @Input() contactsList: any;
  @Input() contactFormat: any;

  constructor() { }

  ngOnInit() {}

  selectContact(contact) {
    this.contactSelectedEmit.emit(contact);
  }

  favoriteContact() {
    console.log('favoriteContact')
  }
}
