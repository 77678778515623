import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
    observeCorporateUser: Observable<any>;
    observeCurrentUser: Observable<any>;
    private sharedSubjectCorporateUser = new Subject<any>();
    private sharedSubjectCurrentUser = new Subject<any>();

    constructor() {
        this.observeCorporateUser = this.sharedSubjectCorporateUser.asObservable();
        this.observeCurrentUser = this.sharedSubjectCurrentUser.asObservable();
    }

    setCorporateUser(data) {
        console.log(data); // I have data! Let's return it so subscribers can use it!
        // we can do stuff with data if we want
        this.sharedSubjectCorporateUser.next(data);
    }

    setCurrentUserData( data ) {
        this.sharedSubjectCurrentUser.next(data);
    }
}