import { Component, OnInit, OnChanges, ViewEncapsulation, Input, SimpleChanges, SimpleChange, HostListener} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/_services/account/account.service';

@Component({
  selector: 'company-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appContactDetailsWrapper'}
})
export class CompanyContactDetailsComponent implements OnInit, OnChanges {
  @Input() contactDetails: any;
  @Input("contactFormat") contactFormat: string;
  @Input() viewPersonalData: boolean = true;
  @Input() viewProfessionalData: boolean = false;
  @Input() viewCompanyGeneralData: boolean = false;
  @Input() viewCompanyRelationships: boolean = false;
  @Input() viewHistory: boolean = false;
  @Input() viewLog: boolean = false;

  @HostListener('document:click', ['$event.target']) 
    onClick(target) {
      if( target.id !== 'edit-contact' )  
        this.contactMenuOpen = false;
    }

  private accountSubs: Subscription;
  private contactMenuOpen: boolean = false;
  private contactSelected: SimpleChange;
  private sectionDetailsContact: SafeHtml;
  private sectionDetailsPersonal: SafeHtml;
  private sectionDetailsProfessional: SafeHtml;
  private sectionDetailsHistory: SafeHtml;

  public account: any;

  constructor(
    private sanitizer: DomSanitizer, 
    private accountService: AccountService) { }


  ngOnInit() {
    this.accountService.observeAccountType
      .subscribe( account => { 
        this.account = account;
        console.log(account) 
      });
    
      console.log(this.contactSelected)

    if( this.contactFormat === 'institution' ) {
      this.onViewReset();
      this.viewCompanyGeneralData = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Selected contact details
    this.contactSelected = changes.contactDetails.currentValue;
    console.log(this.contactSelected)
    // this.fillContactDetails(this.contactSelected.currentValue);
  }


  private onToggleView(el) {
    this.viewPersonalData = el === 'personal' ? true : false;
    this.viewProfessionalData = el === 'professional' ? true : false;
    this.viewCompanyGeneralData = el === 'general' ? true : false;
    this.viewCompanyRelationships = el === 'relationships' ? true : false;
    this.viewHistory = el === 'history' ? true : false;
    this.viewLog = el === 'log' ? true : false;
  }

  onViewReset() {
    this.viewPersonalData = false;
    this.viewProfessionalData = false;
    this.viewCompanyGeneralData = false;
    this.viewCompanyRelationships = false;
    this.viewHistory = false;
    this.viewLog = false;
  }

  onViewPersonal() { this.onToggleView("personal"); }
  onViewProfessional() { this.onToggleView("professional"); }
  onViewCompanyGeneralData() { this.onToggleView("general"); }
  onViewCompanyRelationships() { this.onToggleView("relationships"); }
  onViewHistory() { this.onToggleView("history"); }
  onViewLog() { this.onToggleView("log"); }

  
  // editContact(e) {
  //   e.preventDefault();
  //   console.log(this.contactDetails);
  //   this.contactMenuOpen = true;
  // }

  private resetInfoHtml() {
    this.sectionDetailsContact = '';
    this.sectionDetailsPersonal = '';
    this.sectionDetailsProfessional = '';
    this.sectionDetailsHistory = '';
  }

  
}
