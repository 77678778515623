import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-company',
  template: '<router-outlet></router-outlet>'
})
export class CompanyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
