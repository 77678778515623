import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { AccountService } from 'src/app/_services/account/account.service';
import { CompanyService } from 'src/app/_services/company/company.service';
import { Subscription } from 'rxjs';
import { CompanyExistsModule } from '../../account/company-create/company-exists/exists.module';
// import { CompanyService } from 'src/app/_services/company/company.service';

@Component({
  selector: 'app-account-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appContactWrapper'}
})
export class CompanyContactComponent implements OnInit, OnDestroy {
  private selectedOrientation: string = 'details';
  private showComments: boolean = false;
  private selectedCompanyId: string;
  private selectedContactFormat: string;
  private subscription: Subscription[] = [];
  private selectedContact: any;
  private contactsAux: any;
  private institutionContacts = [];
  private personContacts = [];
  private contactsDisplay = [];

  constructor(
    private authService: AuthService,
    private router: ActivatedRoute, 
    private companyService: CompanyService,
    private accountService: AccountService) { }


  ngOnInit() {
    this.router.paramMap.subscribe(params => {
      if( params.has('companyId') ) {
        console.log(' | Company | -- Instance');
        this.accountService.setAccountType({
          companyId: params.get('companyId'),
          type: 'company'
        });

      } else {
        console.log(' | Account | -- Instance');
        this.accountService.setAccountType({ 
          type: 'account' 
        });
      }
    });

    this.router.queryParams.subscribe(params => {
      console.log(params)
      // ref: data.id, format: 'person'
      const contactId = params['ref'];
      const contactFormat = params['format'];
      

      // BUSCAR E MOSTRAR OS DETAILS DO CONTATO DE ACORDO COM A SUA ID E O SEU TIPO
      console.log(contactId)
      console.log(contactFormat)
    });

    this.selectedCompanyId = localStorage.getItem('selected-company-id');

    if ( !this.selectedCompanyId ) {
      this.subscription.push(
        this.companyService.observeCorporateUser
          .subscribe( el => this.selectedCompanyId = el )
      )
    }

    this.fetchContactsByType( 'person' );
  }

  ngOnDestroy() {
    this.subscription.forEach( subscription => subscription.unsubscribe() );
  }

  private fetchContactsByType( option )  {
    if( option === 'person' ) {
      this.subscription.push(
        this.authService.getCompanyContactsPerson( this.selectedCompanyId )
          .subscribe( contacts => {
            this.personContacts = contacts;
            this.contactsDisplay = contacts;
          })
      );
    }
    else if( option === 'institution' ) {
      this.subscription.push(
        this.authService.getCompanyContactsInstitution( this.selectedCompanyId )
          .subscribe( contacts => {
            this.institutionContacts = contacts;
            this.contactsDisplay = contacts;
          })
      );
    }

    this.contactsAux = this.personContacts || this.institutionContacts;
  }

  public onContactSelect(contact) {
    this.selectedContact = contact;
    this.showComments = true;
  }

  public closeComments(toggle: boolean) {
    this.showComments = toggle;
  }

  public onOrientationSelected(event) {
    this.selectedOrientation = event;
    console.log(event)
  }
  
  public onSearchContact(query, contactFormat) {
    let result;

    if ( contactFormat === 'person' ) {
      result = this.personContacts.filter( person => {
        return (
          person.name.toLowerCase().indexOf(query) !== -1 || !query
        )
      });
    }
    else if ( contactFormat === 'institution' ) {
      result = this.institutionContacts.filter( institution => {
        return (
          institution.tradingName.toLowerCase().indexOf(query) !== -1 || 
          institution.name.toLowerCase().indexOf(query) !== -1 || 
          !query
        )
      });
    }

    this.contactsDisplay = result;
  }

  public onChangeContactFormat ( option ): void {
    this.selectedContactFormat = option;
    
    if ( option === 'person' ) {
      this.fetchContactsByType( option );
      this.contactsDisplay = this.personContacts;
    } 
    else if ( option === 'institution' ) {
      this.fetchContactsByType( option );
      this.contactsDisplay = this.institutionContacts;

    } 
    else {
      console.log('else')
    }

    this.selectedContact = '';
  }
}
