import { Routes } from '@angular/router';

import { AuthRoutes } from './modules/auth/auth.routes';
import { ClientRoutes } from './modules/client/client.routes';

export const routes: Routes = [
   ...AuthRoutes, 
   ...ClientRoutes,
   
   {
      path: 'api/company/invite/accept',
      redirectTo: 'client/account/overview'
   },
];
