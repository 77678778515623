import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild } from '@angular/router';

import { AuthService } from '../../_services/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ClientAuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private cookieService: CookieService,
        private authService: AuthService
    ) {}    

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkUser(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkUser(route, state);
    }

    private checkUser(route, state) {
        const userToken = this.cookieService.check('id_token');

        if( userToken ) {
            return true;
        } else {
            this.router.navigate(['../auth/login']);
            return false;
        }
    }
}