import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'app-company-exists',
  templateUrl: './exists.component.html',
  styleUrls: ['./exists.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'companyExistsModal'}
})
export class CompanyExistsComponent implements OnInit {
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onRecoverCompanyAccount = new EventEmitter<boolean>();
  @Input() companyExistsOpen: boolean;
  @Input() data: any;
  
  constructor(private authService: AuthService) { }

  ngOnInit() { }

  onModalClose() {
    this.onClose.emit( !this.companyExistsOpen );
  }

  onAccessRequest() {
    this.companyExistsOpen = false;
    this.onRecoverCompanyAccount.emit( true );

    this.authService.recoverCompanyAccount( this.data )
      .subscribe(
        data => { 
          alert('E-mail enviado!');
        },
        error => {
          console.log(error);
        }
      );
  }

}
