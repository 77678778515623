import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { NgxMaskModule, IConfig } from 'ngx-mask-2';
import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from './_services/loader.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AgGridModule } from 'ag-grid-angular';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { ClientModule } from './modules/client/client.module';
import { ClientAuthGuard } from './core/guard/client-auth.guard';
import { fakeBackendProvider } from './_helpers/fake-backend';
import { routes } from './app.routes';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { useHash: true }),
    NgxMaskModule.forRoot(options),
    NgxDatatableModule,
    AgGridModule.withComponents([]),
    AuthModule,
    ClientModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    fakeBackendProvider,
    CookieService,
    ClientAuthGuard,
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

