import { Routes } from '@angular/router';

import { CompanyEditComponent } from './company-edit/edit.component';
import { CompanyOverviewComponent } from './company-overview/overview.component';
// import { CompanyCreateComponent } from '../account/company-create/create.component';
import { EventsComponent } from '../events/events.component';
import { CompanyContactComponent } from './company-contact/contact.component';
import { CompanyContactEditComponent } from './company-contact/contact-edit/contact-edit.component';
import { CompanyContactInstitutionEditComponent } from './company-contact/contact-institution-edit/contact-edit.component';
import { TeamRoutes } from './team/team.routes';

export const CompanyRoutes: Routes = [
  {
    path: ':companyId',
    children: [
      {
        path: 'edit',
        component: CompanyEditComponent
      },
      {
        path: 'overview',
        component: CompanyOverviewComponent
      },
      {
        path: 'events',
        component: EventsComponent
      },
      {
        path: 'contact',
        component: CompanyContactComponent,
      },
      {
        path: 'contact/new',
        component: CompanyContactEditComponent
      },
      {
        path: 'contact/:contactId/edit',
        component: CompanyContactEditComponent
      },
      {
        path: 'contact/institution/new',
        component: CompanyContactInstitutionEditComponent
      },
      {
        path: 'contact/:contactId/institution/edit',
        component: CompanyContactInstitutionEditComponent
      },

      {
        path: 'team',
        children: [...TeamRoutes]
      },
      // {
      //   path: 'financial',
      //   component: 
      // },
      // { 
      //   path: 'templates',
      //   component: 
      // }
    ]
  }
];

