import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthComponent } from './auth.component';

@NgModule({
  imports: [
    CommonModule, 
    RouterModule,
    FormsModule
  ],
  exports: [ AuthComponent ],
  declarations: [ AuthComponent ],
  providers: []
})
export class AuthModule { }

