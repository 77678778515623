import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'owner-add-modal',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'ownerAddModal'}
})
export class OwnerAddComponent implements OnInit, AfterViewInit {
  @Output() onClose = new EventEmitter<boolean>();
  @Input() modalAddOpen: boolean;

  private modalWrapper: any;
  
  constructor(private router: Router) { }

  ngOnInit() { 
    this.modalWrapper = document.getElementById('ownerAddModal') as HTMLElement;
    this.modalWrapper.addEventListener('click', (e) => {
      this.onModalClose();
    });
  }

  ngAfterViewInit() {
    this.setModalPosition();
  }

  setModalPosition(): void {
    const mainHeight: number = (document.querySelector("#appMain") as HTMLElement).offsetHeight;
    const actualScreenHeight: number = document.documentElement.clientHeight;
    const topPosition: number = ( mainHeight - actualScreenHeight ) / 2;

    (document.querySelector("#modal-owner-add") as HTMLElement).style.top = `-${topPosition.toString()}px`;
  }

  modalStopPropagation = ($event) => {
    $event.stopPropagation();
  }

  onModalClose = () => {
    this.onClose.emit( !this.modalAddOpen );
  }

  ownerAdd = () => {
    alert('owner clicked');
  }

  contactAdd = () => {
    alert('item clicked');
  }

}
