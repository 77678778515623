import { Component, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'company-contact-menu',
  templateUrl: './contact-menu.component.html',
  styleUrls: ['./contact-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appContactMenu'}
})
export class CompanyContactMenuComponent implements OnInit {
  @Output() onExportData = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  exportData() {
    this.onExportData.emit();
  }

  deletePersonalData() {
  }

  deleteRegistry() {

  }

}
