import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


import { AuthService } from 'src/app/_services/auth/auth.service';
import { CompanyService } from 'src/app/_services/company/company.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Subscription } from 'rxjs';

@Component({
  selector: 'company-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class CompanyContactInstitutionEditComponent implements OnInit, AfterViewInit, OnDestroy {
  private contactForm: FormGroup;
  private currentUserData: any;
  private submitted: boolean = false;
  private allCountries: any;
  private countries: any = [];
  private states$: any = [];
  private cities$: any = [];
  private selectedCompanyId: string;
  private subscriptions: Subscription[] = [];

  private title = {
    main: "Editar Instituição"
  }
  
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private companyService: CompanyService
  ) { }


  ngOnInit() {
    this.currentUserData = history.state.data;
    console.log(this.currentUserData)

    const url = this.router.url.split('/');
    const lastSegment = this.getLastUrlSegment(url);

    // pensar em uma solução genérica
    if ( lastSegment === "new" ) {
      this.title.main = "Novo Contato - Instituição";
    }

    this.subscriptions.push(
      this.companyService.observeCorporateUser
        .subscribe( el => this.selectedCompanyId = el )
    );
    
    if( !this.selectedCompanyId ) 
      this.selectedCompanyId = localStorage.getItem('selected-company-id');


      /** 
       * INSTITUTION FIELDS
       * 
       * {
          "id": null,
          "tradingName": "Instituição Teste2",
          "name": "T21",
          "primaryEmail": "teste2@it4cio.com",
          "document": "57.246.854/0001-70",
          "stateInscription": "257714",
          "municipalInscription": "25727",
          "imageUrl": null,
          "addresses": [
            {
              "label": "Teste 3",
              "postalCode": "81280-440",
              "line1": "Teste line1",
              "line2": "Teste line2",
              "line3": "Teste line3",
              "cityId": 3711,
              "main": true
            },
          ],
          "phones": [
            {
              "label": "Celular",
              "number": "41996873931",
              "countryCode": "BR",
              "main": true
            },
          ],
          "emails": [
              {
                "label": "Pessoal2",
                "email": "teste21@it4cio.com",
                "main": true
              },
            ]
        }
      */



     this.contactForm = this.formBuilder.group({
      // ___Institutional Data
      _id: [ '' ],
      imageUrl: null,
      isSupplier: [ '' ],
      isSponsor: [ '' ],
      isPartner: [ '' ],
      isEvents: [ '' ],
      tradingName: [ '', [ Validators.required, Validators.maxLength(255)]],
      name: [ '', [ Validators.maxLength(255) ]],
      document: [ '', [ Validators.minLength(9), Validators.maxLength(18) ]],
      stateInscription: [ '' ,  Validators.maxLength(255) ],
      municipalInscription: [ '', Validators.maxLength(255) ],
      about: [ '' ],
      relationshipStatus: [ '' ],
      relationshipResponsible: [ '' ],
      
      emailMulti: this.formBuilder.array([]),
      phoneMulti: this.formBuilder.array([]),
      addressMulti: this.formBuilder.array([])
    });

  }

  ngAfterViewInit() { 
    const contactId = this.route.snapshot.paramMap.get('contactId');

    if( contactId ) {
      this.subscriptions.push(
        this.authService.getCompanyContactInstitution( this.selectedCompanyId, contactId )
          .subscribe(
            data => this.populateForm( data ),
            error => console.log(error)
          )
        );

    } else {
      this.populateForm( undefined );
    }

    this.subscriptions.push(
      this.authService.getCountries()
        .subscribe(
          countries => this.allCountries = countries,
          error => { console.log(error) }
        )
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subscription => subscription.unsubscribe());
  }
  
  private populateForm( data ) {
    console.log(data)
    if( data ) {
      this.contactForm.patchValue({
        _id: data.id,
        // image: data.imageUrl,
        isSupplier: data.isSupplier,
        isSponsor: data.isSponsor,
        isPartner: data.isPartner,
        isEvents: data.isEvents,
        tradingName: data.tradingName,
        name: data.name,
        document: data.document,
        stateInscription: data.stateInscription,
        municipalInscription: data.municipalInscription,
        relationshipStatus: data.relationshipStatus,
        relationshipResponsible: data.relationshipResponsible,
        about: data.about,
      });
  
      if ( data.emails && data.emails.length > 0 ) 
        this.populateFormMulti( data.emails, 'email' );
      else 
        this.addEmailInput( true );

      if ( data.phones && data.phones.length > 0 ) 
        this.populateFormMulti( data.phones, 'phone' );
      else 
        this.addPhoneInput( true );

      if ( data.addresses && data.addresses.length > 0 ) 
        this.populateFormMulti( data.addresses, 'address' );
      else
        this.addAddressInput( true );

    } else {
      this.addEmailInput( true );
      this.addPhoneInput( true );
      this.addAddressInput( true );
    }
  }

  private populateFormMulti( data, type ) {
    let index = 0;
    data.sort( (a, b) => b.main - a.main );
  
    data.forEach( el => {
      switch( type ) {
        case 'email': 
          this.addEmailInput( undefined, el );
          break;
          
        case 'phone': 
          this.addPhoneInput( undefined, el );
          break;
          
        case 'address': {
          let countryCode = el.countryCode;
          let stateCode = el.stateCode;
  
          this.setStatesByCode( countryCode, index );
          this.setCitiesByCode( stateCode, countryCode, index );

          this.addAddressInput( undefined, el );

          index++;
          break;
        }
      }
    })
  }

  get f() { return this.contactForm.controls; }
  get emailMulti() { return this.contactForm.get('emailMulti') as FormArray; }
  get phoneMulti() { return this.contactForm.get('phoneMulti') as FormArray; }
  get addressMulti() { return this.contactForm.get('addressMulti') as FormArray; }

  public addEmailInput( isMain?: boolean, emailData?: any ): void {
    let control;

    if ( emailData ) {
      control = this.formBuilder.group({
        id: emailData.id,
        label: emailData.label,
        email: emailData.email,
        main: emailData.main,
        pendingConfirmation: emailData.pendingConfirmation
      });

    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        email: [ '', [ Validators.email, Validators.maxLength(255) ]],
        main: isMain ? isMain : [ '' ],
        pendingConfirmation: [ '' ]
      });
    }

    this.emailMulti.push(control);
  }

  public addPhoneInput( isMain?: boolean, phoneData?: any ): void {
    let control;
    
    if ( phoneData ) {
      control = this.formBuilder.group({
        id: phoneData.id,
        label: phoneData.label,
        number: phoneData.number,
        countryCode: phoneData.countryCode,
        main: phoneData.main
      });

    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        number: [ '' ],
        countryCode: [ '' ],
        main: isMain ? isMain : [ '' ]
      });
    }

    this.phoneMulti.push(control);
  }

  public addAddressInput( isMain?: boolean, addressData?: any ): void {
    let control;

    if ( addressData ) {
      control = this.formBuilder.group({
        id: addressData.id,
        label: addressData.label,
        postalCode: addressData.postalCode,
        countryCode: addressData.countryCode,
        countryName: addressData.countryName,
        stateCode: addressData.stateCode,
        stateName: addressData.stateName,
        cityId: addressData.cityId,
        cityName: addressData.cityName,
        line1: addressData.line1,
        line2: addressData.line2,
        line3: addressData.line3,
        main: addressData.main
      });
      
    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        postalCode: [ '' ],
        countryCode: [ '' ],
        countryName: [ '' ],
        stateCode: [ '' ],
        stateName: [ '' ],
        cityId: [ '' ],
        cityName: [ '' ],
        line1: [ '' ],
        line2: [ '' ],
        line3: [ '' ],
        main: isMain ? isMain : [ '' ]
      });
    }

    this.addressMulti.push(control);
  }


  public removeEmailInput(index: number): void {
    this.emailMulti.removeAt(index);
  }

  public removePhoneInput(index: number): void {
    this.phoneMulti.removeAt(index);
  }

  public removeAddressInput(index: number): void {
    this.addressMulti.removeAt(index);
  }


  public favouriteEmail( emailSelected: any ): void {
    let allEmails = this.emailMulti.controls;

    allEmails.forEach( address => {
      address.value.main = false;
    });

    emailSelected.value.main = true;
  }

  public favouritePhone( phoneSelected: any ): void {
    let allPhones = this.phoneMulti.controls;

    allPhones.forEach( phone => {
      phone.value.main = false;
    });

    phoneSelected.value.main = true;
  }

  public favouriteAddress( addressSelected: any ): void {
    let allAddresses = this.addressMulti.controls;

    allAddresses.forEach( address => {
      address.value.main = false;
    });

    addressSelected.value.main = true;
  }


  private setStatesByCode( countryCode: string, index: number ) {
    this.subscriptions.push(
      this.authService.getStatesByCode(countryCode)
        .subscribe(
          data => { this.states$[index] = data },
          error => { console.log(error) }
        )
    );
  }

  private setCitiesByCode( stateCode: string, countryCode: string, index: number ): void {
    this.subscriptions.push(
      this.authService.getCitiesByCode(stateCode, countryCode)
        .subscribe(
          data => { this.cities$[index] = data },
          error => { console.log(error) }
        )
    );
  }


  public onCountryChange( address_: any, index: number ): void {
    const countryCode = address_.value.countryCode;
    this.countries[index] = countryCode;

    address_.controls['cityId'].setValue('');
    address_.controls['stateCode'].setValue('');

    this.setStatesByCode( countryCode, index );
  }

  public onStateChange( address_: any, index ): void {
    const stateCode = address_.value.stateCode;
    const countryCode = address_.value.countryCode;

    this.setCitiesByCode( stateCode, countryCode, index );
  }
  
  
  public onPhoneType( countryCode: any, phone_: any ) {
    let phone = phone_.value.number;
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode)

    if( phoneNumber && phoneNumber.formatNational() !== undefined ) {
      phone = phoneNumber.formatNational();
      phone_.controls['number'].setValue(phone);
    } 
  }
  


  private getLastUrlSegment(route): string {
    var parts = this.router.url.split('/');
    var lastSegment = parts.pop() || parts.pop();
    
    return lastSegment;
  }

  private convertDatePtBr( date ) {
    const dateObj = new Date(date + 'T00:00:00');
    return new Intl.DateTimeFormat('pt-BR').format(dateObj);
  }

  private convertDateUTC( date ) {
    if ( date ) {
      let newDate = date.split('/');
      return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

    } else {
      return '';

    }
  }

  private convertFormValues( contactForm ) {
    let formValues = contactForm.value;

    if( formValues.emailMulti.length === 1 && !formValues.emailMulti[0].label && !formValues.emailMulti[0].email ) 
      formValues.emailMulti = null;

    if( formValues.phoneMulti.length === 1 && !formValues.phoneMulti[0].label && !formValues.phoneMulti[0].countryCode && !formValues.phoneMulti[0].number ) 
      formValues.phoneMulti = null;

    if( formValues.addressMulti.length === 1 && !formValues.addressMulti[0].label && !formValues.addressMulti[0].countryCode && !formValues.addressMulti[0].stateCode && !formValues.addressMulti[0].cityId ) 
      formValues.addressMulti = null;

    if( !formValues.document )
      formValues.document = null;

    return {
      "id": formValues._id,
      "tradingName": formValues.tradingName,
      "name": formValues.name,
      "document": formValues.document,
      "stateInscription": formValues.stateInscription,
      "municipalInscription": formValues.municipalInscription,
      "about": formValues.about,
      "relationshipStatus": formValues.relationshipStatus,
      "relationshipResponsible": formValues.relationshipResponsible,
      "emails": formValues.emailMulti,
      "addresses": formValues.addressMulti,
      "phones": formValues.phoneMulti,
      "languageCode": "pt-br"
    }
  }

  onSubmit = () => {
    this.submitted = true;
    console.log(this.contactForm);

    if(this.contactForm.invalid) {
      return;
      
    } else {
      const formValues = this.convertFormValues(this.contactForm);
      const contactId = this.contactForm.value._id;

      if( !contactId ) {
        this.subscriptions.push(
          this.authService.createCompanyContactInstitution( formValues, this.selectedCompanyId )
            .subscribe(
              data => { 
                this.contactForm.patchValue({ 
                  _id: data.id,
                })
              },
              error => { console.log(error); }
          )
        );
        
      } else {
        this.subscriptions.push(
          this.authService.updateCompanyContactInstitution( formValues, this.selectedCompanyId )
            .subscribe(
              data => { console.log(data) },
              error => { console.log(error); }
            )
        );
      }
    }

    this.submitted = false;
  }
}