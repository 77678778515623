import { Route } from '@angular/router';

import { AuthComponent } from './auth.component';

export const AuthRoutes: Route[] = [
  {
    path: 'auth',
    component: AuthComponent,
        // canActivate: [AuthGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./register/register.module').then(m => m.RegisterModule)
      },
      { 
        path: 'recover-password', 
        loadChildren: () => 
          import('./recover-password/recover-password.module').then(m => m.RecoverPasswordModule) 
      },
      {
        path: 'reset-password',
        loadChildren: () => 
          import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
      },
      {
        path: 'account-activate',
        loadChildren: () => 
          import('./account-activate/account-activate.module').then(m => m.AccountActivateModule)
      },
      { 
        path: 'account-created', 
        loadChildren: () => 
          import('./feedback/account-created/account-created.module').then(m => m.AccountCreatedModule)   
      },
      { 
        path: 'password-recover', 
        loadChildren: () => 
          import('./feedback/password-recover/password-recover.module').then(m => m.PasswordRecoverModule)
      },
      { 
        path: 'password-redefined', 
        loadChildren: () => 
          import('./feedback/password-redefined/password-redefined.module').then(m => m.PasswordRedefinedModule)
      },
      {
        path: 'company-invite',
        loadChildren: () => 
          import('./company-invite/company-invite.module').then(m => m.CompanyInviteModule)
      },
      {
        path: '',
        loadChildren: () => 
          import('./login/login.module').then(m => m.LoginModule)
      },
      { 
        path: '**',  
        loadChildren: () =>
          import('./feedback/notfound/notfound.module').then(m => m.NotFoundModule)
      }
    ]
  },
];

