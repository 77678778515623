import { Component, OnInit, OnChanges, ViewEncapsulation, Input, SimpleChanges, SimpleChange, HostListener} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/_services/account/account.service';

@Component({
  selector: 'contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appContactDetailsWrapper'}
})
export class AccountContactDetailsComponent implements OnInit, OnChanges {
  @Input() contactDetails: any;
  @Input() viewPersonalData: boolean = true;
  @Input() viewProfessionalData: boolean = false;
  @Input() viewHistory: boolean = false;
  @Input() viewLog: boolean = false;

  @HostListener('document:click', ['$event.target']) 
    onClick(target) {
      if( target.id !== 'edit-contact' )  
        this.contactMenuOpen = false;
    }

  private accountSubs: Subscription;
  private contactMenuOpen: boolean = false;
  private contactSelected: SimpleChange;
  private sectionDetailsContact: SafeHtml;
  private sectionDetailsPersonal: SafeHtml;
  private sectionDetailsProfessional: SafeHtml;
  private sectionDetailsHistory: SafeHtml;

  public account: any;

  constructor(
    private sanitizer: DomSanitizer, 
    private accountService: AccountService) { }

  ngOnChanges(changes: SimpleChanges) {
    // Selected contact details
    this.contactSelected = changes.contactDetails.currentValue;
    console.log(this.contactSelected)
    // this.fillContactDetails(this.contactSelected.currentValue);
  }

  ngOnInit() {
    this.accountService.observeAccountType
      .subscribe( account => this.account = account );
  }


  private onToggleView(el) {
    this.viewPersonalData = el === 'personal' ? true : false;
    this.viewProfessionalData = el === 'professional' ? true : false;
    this.viewHistory = el === 'history' ? true : false;
    this.viewLog = el === 'log' ? true : false;
  }

  onViewReset() {
    this.viewPersonalData = false;
    this.viewProfessionalData = false;
    this.viewHistory = false;
    this.viewLog = false;
  }

  onViewPersonal() { this.onToggleView("personal"); }
  onViewProfessional() { this.onToggleView("professional"); }
  onViewHistory() { this.onToggleView("history"); }
  onViewLog() { this.onToggleView("log"); }

  
  // editContact(e) {
  //   e.preventDefault();
  //   console.log(this.contactDetails);
  //   this.contactMenuOpen = true;
  // }

  private resetInfoHtml() {
    this.sectionDetailsContact = '';
    this.sectionDetailsPersonal = '';
    this.sectionDetailsProfessional = '';
    this.sectionDetailsHistory = '';
  }

  private buildInfoHtml(info, type: string, typeTitle: string, typeIcon: string, viewIcon: string) {
    let htmlContainer = '';

    if ( type === 'phone' || type === 'email' ) {
      htmlContainer = `
        <table class="details-info">
          <tr>
            <th rowspan="2" class="info-image-wrapper">
              <img src="${ typeIcon }" alt="" class="info-image">
            </th>
            <th>
              <span class="info-title">${ typeTitle }</span>
            </th>
          </tr>
          <tr>
            <td>
              <span class="info-data">${ info }</span>
            </td>
          </tr>
        </table>`;

    } else if ( type === 'contact' ) {
      htmlContainer = `
        <table class="details-info">
          <tr>
            <th rowspan="2" class="info-image-wrapper">
              <img src="${ typeIcon }" alt="" class="info-image">
            </th>
            <th>
              <span class="info-title">${ typeTitle }</span>
            </th>
            <th rowspan="2" class="info-image-wrapper">
              <img src="${ viewIcon }" alt="" class="info-image info-view-connection">
            </th>
          </tr>
          <tr>
            <td>
              <span class="info-data">${ info }</span>
            </td>
          </tr>
        </table>`;

    } else if ( type === 'address' ) {
      htmlContainer = `
        <table cellspacing="0" class="details-info">
          <tr>
            <th rowspan="5" class="info-image-wrapper address-image">
              <img src="${ typeIcon }" alt="" class="info-image"></th>
            <th colspan="2">
              <span class="info-title">Endereço</span>
            </th>
          </tr>
          <tr>
            <td colspan="2">
              <span class="info-data">${ info.addressPlace }</span>
            </td>
          </tr>
          <tr class="info-address-50">
            <td>
              <span class="info-data">${ info.zipcode }</span>
            </td>
            <td class="info-divisor">
              <span class="info-data">${ info.city }</span>
            </td>
          </tr>
          <tr class="info-address-50">
            <td>
              <span class="info-data">${ info.addressDetails }</span>
            </td>
            <td>
              <span class="info-data">${ info.country }</span>
            </td>
          </tr>
        </table>`
    }

    return htmlContainer;
  }
  

  private displayInfoWidget = ( data: any, type: string, view ) => {
    let typeIcon: string,
        viewIcon: string,
        typeTitle: string;

    if ( type === 'phone' ) {
      typeIcon = '../../../../../assets/client/icons/icon-phone.png';
      typeTitle = 'Celular';

    } else if ( type === 'email' ) {
      typeIcon = '../../../../../assets/client/icons/icon-email.png';
      typeTitle = 'E-mail';

    } else if ( type === 'address' ) {
      typeIcon = '../../../../../assets/client/icons/icon-map-mark.png';
      
    } else if ( type === 'contact' ) {
      typeIcon = '../../../../../assets/client/icons/icon-profile.png';
      viewIcon = '../../../../../assets/client/icons/icon-view.png';
      // Esta informação deverá vir dinamicamente
      typeTitle = 'Irmão';
    }

    if ( typeof data !== 'string' && typeof data !== 'undefined' ) {
      return data.map(info => {
        return this.buildInfoHtml(info, type, typeTitle, typeIcon, viewIcon);
      }).join('');

    } else {
      return this.buildInfoHtml(data, type, typeTitle, typeIcon, viewIcon);
    }
  };


  private fillPersonalDetails = ( contact ) => {

    this.sectionDetailsPersonal = this.sanitizer.bypassSecurityTrustHtml(`
      <h1 class="title-main">Dados Pessoais</h1>
      
      ${ this.displayInfoWidget(contact.phone, 'phone', '') }
      
      <hr>
  
      ${ this.displayInfoWidget(contact.email, 'email', '') }
  
      <hr>

      ${ this.displayInfoWidget(contact.address, 'address', '') }
  
      <h1 class="title-main">Vínculos</h1>
  
      <table class="details-info">
        <tr>
          <th rowspan="2" class="info-image-wrapper">
            <img src="../../../../../assets/client/icons/icon-profile.png" alt="" class="info-image">
          </th>
          <th>
            <span class="info-title">Irmão</span>
          </th>
          <th rowspan="2" class="info-image-wrapper">
            <img src="../../../../../assets/client/icons/icon-view.png" alt="" class="info-image info-view-connection">
          </th>
        </tr>
        <tr>
          <td>
            <span class="info-data">Rodrigo de Mendonça Oliveira</span>
          </td>
        </tr>
      </table>
  
      <table class="details-info">
        <tr>
          <th rowspan="2" class="info-image-wrapper">
            <img src="../../../../../assets/client/icons/icon-profile.png" alt="" class="info-image">
          </th>
          <th>
            <span class="info-title">Irmão</span>
          </th>
          <th rowspan="2" class="info-image-wrapper">
            <img src="../../../../../assets/client/icons/icon-view.png" alt="" class="info-image info-view-connection">
          </th>
        </tr>
        <tr>
          <td>
            <span class="info-data">Rodrigo de Mendonça Oliveira</span>
          </td>
        </tr>
      </table>
    `);
  }


  private fillProfessionalDetails = ( contact ) => {

    this.sectionDetailsProfessional = this.sanitizer.bypassSecurityTrustHtml(`
      <h1 class="title-main">Dados Profissionais</h1>
      
      ${ this.displayInfoWidget(contact.companyPhone, 'phone', '') }
      
      <hr>
  
      ${ this.displayInfoWidget(contact.companyEmail, 'email', '') }
  
      <hr>

      ${ this.displayInfoWidget(contact.companyAddress, 'address', '') }
  
      <h1 class="title-main">Vínculos</h1>
  
      <table class="details-info">
        <tr>
          <th rowspan="2" class="info-image-wrapper">
            <img src="../../../../../assets/client/icons/icon-profile.png" alt="" class="info-image">
          </th>
          <th>
            <span class="info-title">CIO</span>
          </th>
          <th rowspan="2" class="info-image-wrapper">
            <img src="../../../../../assets/client/icons/icon-view.png" alt="" class="info-image info-view-connection">
          </th>
        </tr>
        <tr>
          <td>
            <span class="info-data">Rodrigo de Mendonça Oliveira</span>
          </td>
        </tr>
      </table>
    `);
  }
  

  private fillContactHistory = ( contact ) => {
    this.sectionDetailsHistory = this.sanitizer.bypassSecurityTrustHtml(`
      <h1 class="title-main">Histórico</h1>

      <h1 class="title-main subtitle">
        <img src="../../../../../assets/client/icons/icon-events.png" alt="" class="info-image">Eventos
      </h1>

      <table class="details-info info-events">
        <tr>
          <th colspan="2">
           <span class="info-data">2015 - Nome do Evento</span>
          </th>
        </tr>
        <tr>
          <td colspan="2" class="event-gap">
           <span class="info-data">Cidade - UF</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="info-title">Representante</span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="event-gap">
           <span class="info-data">Nome do Representante</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="info-title">Tipo de Participante</span>
          </td>
          <td>
           <span class="info-title">Cota</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="info-data">Tipo de Participante</span>
          </td>
          <td>
           <span class="info-data">Tipo de Cota</span>
          </td>
        </tr>
      </table>

      <h1 class="title-main subtitle">
        <img src="../../../../../assets/client/icons/icon-company.png" alt="" class="info-image">Empresas
      </h1>

      <table class="details-info info-companies">
        <tbody>
          </tr>
            <td>
              <span class="info-title">2015</span>
            </td>
          </tr>
          </tr>
            <td>
             <span class="info-data">Nome da Empresa</span>
            </td>
          </tr>
          </tr>
            <td class="info-title">Cargo</td>
          </tr>
        </tbody>
      </table>

      <table class="details-info info-companies">
        <tbody>
          </tr>
            <td>
              <span class="info-title">2015</span>
            </td>
          </tr>
          </tr>
            <td>
             <span class="info-data">Nome da Empresa</span>
            </td>
          </tr>
          </tr>
            <td class="info-title">Cargo</td>
          </tr>
        </tbody>
      </table>

      <h1 class="title-main subtitle">
        <img src="../../../../../assets/client/icons/icon-prize.png" alt="" class="info-image">Premiações
      </h1>
      
      <table class="details-info info-awards">
          <tbody>
            </tr>
              <td>
                <span class="info-title">2015</span>
              </td>
            </tr>
            </tr>
              <td>
               <span class="info-data">Nome do Prêmio</span>
              </td>
            </tr>
          </tbody>
        </table>
    `);
  }

  fillContactDetails(contact) {
    this.resetInfoHtml();

    console.log(contact)

    if ( contact ) {
      this.sectionDetailsContact = this.sanitizer.bypassSecurityTrustHtml(`
        <div class="contact-info">
          <div class="vertical-div"></div>
  
          <div class="info-wrapper">
            <h1>${contact.name}</h1>
            <!-- <span class="info-span">${contact.jobrole}</span> -->
            <!-- <small>${contact.company}</small> -->
          </div>
        </div>
      `);
      
      // this.fillPersonalDetails(contact);
      // this.fillProfessionalDetails(contact);
      // this.fillContactHistory(contact);
    }
  }
}
