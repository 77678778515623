import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/app/_services/auth/auth.service';
import { ClientComponent } from '../../client.component';
import { SharedService } from 'src/app/core/shared/shared.service';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/_services/company/company.service';

@Component({
  selector: 'app--edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyEditComponent implements OnInit, AfterViewInit, OnDestroy {
  private profileForm: FormGroup;
  private currentUserData: any;
  private companyData: Object;
  private companyId: number;
  private selectedCompanyId: string;
  private submitted: boolean = false;
  private subscription: Subscription[] = [];
  private title = {
    main: "Editar perfil Corporativo"
  }

  constructor(
    private router: Router, 
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    private clientComponent: ClientComponent) { }

  ngOnInit() {
    // const url = this.router.url.split('/');
    // const lastSegment = this.getLastUrlSegment(url);
    
    // pensar em uma solução genérica
    // if ( lastSegment === "edit" ) {
    //   this.title.main = "Editar perfil Corporativo";
    // }

    this.activatedRoute.paramMap.subscribe(params => { 
      this.companyId = parseInt(params.get('id'));
      // this.sharedService.setCorporateUser(this.companyId);
    });

    this.selectedCompanyId = localStorage.getItem('selected-company-id');

    if ( !this.selectedCompanyId ) {
      this.subscription.push(
        this.companyService.observeCorporateUser
          .subscribe( el => this.selectedCompanyId = el )
      )
    }

    // this.sharedService.setCorporateUser('1337');

      
    this.profileForm = this.formBuilder.group({
      companyName: [ '', [ Validators.required, Validators.maxLength(255)]],
      countryOrigin: [ '', Validators.required ],
      institutionCity: [ '', Validators.required ],
      institutionState: [ '', Validators.required, Validators.maxLength(15) ],
      companyDocument: [ '', Validators.required ],
      corporateEmail: [ '', [ Validators.required, Validators.email, Validators.maxLength(255) ]],
      emailOptional: this.formBuilder.array([
        this.formBuilder.group({
          emailOpt: [ '', [ Validators.email, Validators.maxLength(255) ]]
        })
      ]),
      cellphone: [ '', [ Validators.required, Validators.minLength(11), Validators.maxLength(15) ]],
      cellphoneOptional: this.formBuilder.array([]),
      phone: [ '', [ Validators.minLength(10), Validators.maxLength(15) ]],
      phoneOptional: this.formBuilder.array([]),
      about: [ '' ],

      addresses: this.formBuilder.array([
        this.formBuilder.group({
          country: [ '' ],
          zipcode: [ '' ],
          city: [ '' ],
          state: [ ''],
          address: [ '' ],
          addressDetails: [ '', Validators.maxLength(255) ]
        })
      ]) 
    });
  }
  

  ngAfterViewInit() { 
    this.subscription.push(
      this.authService.getCompaniesById(this.selectedCompanyId)
        .subscribe(
          data => {
            this.companyData = data;
            
            this.profileForm.patchValue({
              companyName: data.name,
              countryOrigin: "",
              companyDocument: data.document,
              corporateEmail: data.corporateEmail,
              cellphone: data.phone,
              phone: data.phone,
            }) 
          },
          error => { console.log(error) }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.forEach( subscription => subscription.unsubscribe() );
  }
  

  get f() { return this.profileForm.controls; }
  get emailOptional() { return this.profileForm.get('emailOptional') as FormArray; }
  get cellphoneOptional() { return this.profileForm.get('cellphoneOptional') as FormArray; }
  get phoneOptional() { return this.profileForm.get('phoneOptional') as FormArray; }
  get addresses() { return this.profileForm.get('addresses') as FormArray; }


  addEmailInput(): void {
    const control = this.formBuilder.group({
      emailOpt: [ '', [ Validators.email, Validators.maxLength(255) ]]
    });
    this.emailOptional.push(control);
  }
  addCellphoneInput(): void {
    const control = this.formBuilder.group({
      cellphoneOpt: [ '', [ Validators.minLength(10), Validators.maxLength(15) ]]
    });
    this.cellphoneOptional.push(control);
  }
  addPhoneInput(): void {
    const control = this.formBuilder.group({
      phoneOpt: [ '', [ Validators.minLength(10), Validators.maxLength(15) ]]
    });
    this.phoneOptional.push(control);
  }


  removeEmailInput(index: number): void {
    this.emailOptional.removeAt(index);
  }
  removeCellphoneInput(index: number): void {
    this.cellphoneOptional.removeAt(index);
  }
  removePhoneInput(index: number): void {
    this.phoneOptional.removeAt(index);
  }


  showAddress(teste) {
    console.log(teste)
    // console.log(this.profileForm.get('addresses'));
  }

  removeAddressInput(index) {
    this.addresses.removeAt(index);
  }

  addAddress(): void {
    const control = this.formBuilder.group({
      country: [ '', Validators.required ],
      zipcode: [ '', Validators.required ],
      city: [ '', Validators.required ],
      state: [ '', Validators.required ],
      address: [ '', Validators.required ],
      addressDetails: [ '', Validators.maxLength(255) ]
    });

    this.addresses.push(control);
  }

  getLastUrlSegment(route): string {
    var parts = this.router.url.split('/');
    var lastSegment = parts.pop() || parts.pop();
    
    return lastSegment;
  }

  processFile = ( input ) => {
    console.log(input.files[0]);
  }

  convertFormValues = (formValues) => {
    return {
      "id": this.companyId,
      "document": formValues.companyDocument,
      "name": formValues.companyName,
      "phone": formValues.phone,
      "corporateEmail": formValues.email,
    }
  }

  onSubmit = () => {
    this.submitted = true;
    console.log(this.profileForm);

    if(this.profileForm.invalid) {
      return;
      
    } else {
      const formValues = this.convertFormValues(this.profileForm.value);

      this.subscription.push(
        this.authService.updateCompany( formValues )
          .subscribe(
            data => { console.log(data); },  
            error => { console.log(error); }
          )
      );

      console.log(this.profileForm);
    }
  }
}