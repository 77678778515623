import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/_services/account/account.service';
import { CompanyService } from 'src/app/_services/company/company.service';

@Component({
  selector: 'app-account-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'appContactWrapper'}
})
export class AccountContactComponent implements OnInit {
  private showComments: boolean = false;
  private selectedOrientation: string = 'details';
  private contactsAux = [];

  selectedContact: any;

  private dummyContactsList = [];

  private personContactsDummy = [
    {
      id: 1,
      name: "Lovisa Andreasson",
      bonds: [
        {
          kind: "Irmão",
          name: "Rodrigo de Mendonça Oliveira"
        },
        {
          kind: "Mãe",
          name: "Sofie Hubert"
        }
      ],
      emails: [
        {
          address: "lovisa@jourrapide.com",
          type: "E-mail",
          isFavorite: true
        },
        {
          address: "andreasson@jourrapide.com",
          type: "Secretária",
          isFavorite: false
        },
        {
          address: "lovisaandreasson@jourrapide.com",
          type: "Comercial",
          isFavorite: false
        }
      ],
      jobrole: "Silvering applicator",
      company: "Envirotecture Design",
      companyEmails: [
        {
          address: "lovisaandreasson@envirotecture.com",
          type: "Principal",
          isFavorite: true
        },
        {
          address: "LA@envirotecture.com",
          type: "Principal",
          isFavorite: false
        }
      ],
      companyPhones: [
        {
          number: "0314-198029",
          type: "Principal",
          isFavorite: true
        },
        {
          number: "0314-872348",
          type: "Principal",
          isFavorite: false
        }
      ],
      companyAddresses: [
        {
          number: 991,
          street: "Melba Court",
          city: "Gila",
          state: "Mississippi",
          stateAbbreviation: "MS",
          zipcode: 48248,
          isFavorite: false
        },
        {
          number: 629,
          street: "Willoughby Street",
          city: "Sharon",
          state: "Illinois",
          stateAbbreviation: "IL",
          zipcode: 17285,
          isFavorite: false
        }
      ],
      companyBonds: [
        {
          kind: "Secretária",
          name: "Matilda Evans"
        }
      ],
      phones: [
        {
          number: "0548-7826495",
          type: "Celular",
          isFavorite: true
        },
        {
          number: "0128-3019398",
          type: "Celular",
          isFavorite: false
        },
        {
          number: "0278-7606518",
          type: "Comercial",
          isFavorite: false
        }
      ],
      addresses: [
        {
          number: 463,
          street: "Thomas Street",
          city: "Craig",
          state: "District Of Columbia",
          stateAbbreviation: "DC",
          zipcode: 70390,
          isFavorite: true
        },
        {
          number: 629,
          street: "Willoughby Street",
          city: "Sharon",
          state: "Illinois",
          stateAbbreviation: "IL",
          zipcode: 17285,
          isFavorite: false
        }
      ],
    },
    {
      id: 2,
      name: "Timmie Hansson",
      bonds: [
        {
          kind: "Pai",
          name: "Marcos dos Santos"
        },
        {
          kind: "Amigo",
          name: "Luciano Lima Gavião"
        }
      ],
      emails: [
        {
          address: "timmiehansson@dayrep.com",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "timmiehansson@dayrep.com",
      jobrole: "Funeral attendant",
      company: "Pak and Save",
      companyEmails: [
        {
          address: "timmiehansson@pas.com",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0019-5731321",
          type: "Principal",
          isFavorite: true
        }
        
      ],
      companyAddresses: [
        {
          number: 463,
          street: "Thomas Street",
          city: "Craig",
          state: "District Of Columbia",
          stateAbbreviation: "DC",
          zipcode: 70390,
          isFavorite: false
        },
      ],
      companyBonds: [
        {
          kind: "Secretária",
          name: "Helena de Abreu"
        }
      ],
      phones: [
        {
          number: "0523-7868224",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 299,
          street: "Quentin Street",
          city: "Deseret",
          state: "West Virginia",
          stateAbbreviation: "WV",
          zipcode: 62842,
          isFavorite: true
        },
        {
          number: 463,
          street: "Thomas Street",
          city: "Craig",
          state: "District Of Columbia",
          stateAbbreviation: "DC",
          zipcode: 70390,
          isFavorite: false
        },
      ]
    },
    {
      id: 3,
      name: "Ture Olofsson",
      emails: [
        {
          address: "tureolofsson@jourrapide.com",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "tureolofsson@jourrapide.com",
      jobrole: "Vending machine technician",
      company: "Gino's Hamburgers",
      companyEmails: [
        {
          address: "tureolofsson@gino.com",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0019-5321091",
          type: "Principal",
          isFavorite: true
        }
        
      ],
      companyAddresses: [
        {
          number: 629,
          street: "Willoughby Street",
          city: "Sharon",
          state: "Illinois",
          stateAbbreviation: "IL",
          zipcode: 17285,
          isFavorite: false
        }
      ],
      phones: [
        {
          number: "0430-5135073",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 669,
          street: "Eldert Lane",
          city: "Clara",
          state: "Palau",
          stateAbbreviation: "PW",
          zipcode: 30230,
          isFavorite: false
        },
        {
          number: 568,
          street: "Village Court",
          city: "Siglerville",
          state: "Washington",
          stateAbbreviation: "WA",
          zipcode: 56624,
          isFavorite: true
        },
        {
          number: 343,
          street: "Ash Street",
          city: "Skyland",
          state: "New Hampshire",
          stateAbbreviation: "NH",
          zipcode: 65433,
          isFavorite: false
        }
      ]
    },
    {
      id: 4,
      name: "Denize Andersson",
      emails: [
        {
          address: "denizeandersson@teleworm.us",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "denizeandersson@teleworm.us",
      jobrole: "Electronics repairer",
      company: "Plunkett Home Furnishings",
      companyEmails: [
        {
          address: "Plunkett Home Furnishings",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0019-5731091",
          type: "Principal",
          isFavorite: true
        }
        
      ],
      companyAddresses: [
        {
          number: 669,
          street: "Eldert Lane",
          city: "Clara",
          state: "Palau",
          stateAbbreviation: "PW",
          zipcode: 30230,
          isFavorite: false
        },
        
      ],
      phones: [
        {
          number: "0290-9213091",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 206,
          street: "Linwood Street",
          city: "Forbestown",
          state: "Rhode Island",
          stateAbbreviation: "RI",
          zipcode: 30232,
          isFavorite: false
        },
        {
          number: 287,
          street: "Heath Place",
          city: "Disautel",
          state: "Palau",
          stateAbbreviation: "PW",
          zipcode: 44342,
          isFavorite: false
        },
      ]
    },
    {
      id: 5,
      name: "Håkan Bergström",
      emails: [
        {
          address: "hakanbergstrom@rhyta.com",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "hakanbergstrom@rhyta.com",
      jobrole: "Time clerk",
      company: "Forth & Towne",
      companyEmails: [
        {
          address: "hakanbergstrom@fet.com",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0019-5731488",
          type: "Principal",
          isFavorite: true
        }
        
      ],
      companyAddresses: [
        {
          number: 568,
          street: "Village Court",
          city: "Siglerville",
          state: "Washington",
          stateAbbreviation: "WA",
          zipcode: 56624,
          isFavorite: true
        },
      ],
      phones: [
        {
          number: "0413-9442247",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 925,
          street: "Irving Place",
          city: "Tuttle",
          state: "Puerto Rico",
          stateAbbreviation: "PR",
          zipcode: 44407,
          isFavorite: true
        },
        {
          number: 713,
          street: "Woods Place",
          city: "Gambrills",
          state: "Marshall Islands",
          stateAbbreviation: "MH",
          zipcode: 68023,
          isFavorite: false
        },
        {
          number: 980,
          street: "Lincoln Terrace",
          city: "Loretto",
          state: "Florida",
          stateAbbreviation: "FL",
          zipcode: 50452,
          isFavorite: false
        }
      ]
    },
    {
      id: 6,
      name: "Mattis Mattsson",
      emails: [
        {
          address: "mattismattsson@dayrep.com",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "mattismattsson@dayrep.com",
      jobrole: "Graphic artist",
      company: "Creative Wealth Management",
      companyEmails: [
        {
          address: "mattismattsson@cwm.com",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0019-5738191",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyAddresses: [
        {
          number: 925,
          street: "Irving Place",
          city: "Tuttle",
          state: "Puerto Rico",
          stateAbbreviation: "PR",
          zipcode: 44407,
          isFavorite: true
        }
      ],
      phones: [
        {
          number: "08-8676027",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 287,
          street: "Heath Place",
          city: "Disautel",
          state: "Palau",
          stateAbbreviation: "PW",
          zipcode: 44342,
          isFavorite: false
        },
        {
          number: 925,
          street: "Irving Place",
          city: "Tuttle",
          state: "Puerto Rico",
          stateAbbreviation: "PR",
          zipcode: 44407,
          isFavorite: true
        },
      ]
    },
    {
      id: 7,
      name: "Lisen Ivarsson",
      emails: [
        {
          address: "lisenivarsson@teleworm.us",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "lisenivarsson@teleworm.us",
      jobrole: "Economic geographer",
      company: "Chatham",
      companyEmails: [
        {
          address: "lisenivarsson@chatham.us",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0019-5732191",
          type: "Principal",
          isFavorite: true
        }
        
      ],
      companyAddresses: [
        {
          number: 713,
          street: "Woods Place",
          city: "Gambrills",
          state: "Marshall Islands",
          stateAbbreviation: "MH",
          zipcode: 68023,
          isFavorite: false
        }
      ],
      phones: [
        {
          number: "0491-3204506",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 740,
          street: "Farragut Road",
          city: "Bascom",
          state: "Pennsylvania",
          stateAbbreviation: "PA",
          zipcode: 36369,
          isFavorite: true
        },
        {
          number: 432,
          street: "Bills Place",
          city: "Westphalia",
          state: "Kentucky",
          stateAbbreviation: "KY",
          zipcode: 76955,
          isFavorite: false
        },
        {
          number: 457,
          street: "Baycliff Terrace",
          city: "Bend",
          state: "Oregon",
          stateAbbreviation: "OR",
          zipcode: 95734,
          isFavorite: false
        },
      ]
    },
    {
      id: 8,
      name: "Carl-Johan Lindström",
      emails: [
        {
          address: "carl-johanLindstrom@armyspy.com",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "carl-johanLindstrom@armyspy.com",
      jobrole: "Management assistant",
      company: "The Flying Hippo",
      companyEmails: [
        {
          address: "carl-johanLindstrom@hippo.com",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0239-5411091",
          type: "Principal",
          isFavorite: true
        }
        
      ],
      companyAddresses: [
        {
          number: 980,
          street: "Lincoln Terrace",
          city: "Loretto",
          state: "Florida",
          stateAbbreviation: "FL",
          zipcode: 50452,
          isFavorite: false
        }
      ],
      phones: [
        {
          number: "016-4096249",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 655,
          street: "Cropsey Avenue",
          city: "Watchtower",
          state: "Wisconsin",
          stateAbbreviation: "WI",
          zipcode: 98582,
          isFavorite: false
        },
        {
          number: 505,
          street: "Bond Street",
          city: "Hayes",
          state: "Kansas",
          stateAbbreviation: "KS",
          zipcode: 36678,
          isFavorite: false
        },
      ]
    },
    {
      id: 9,
      name: "Whilma Ström",
      emails: [
        {
          address: "WhilmaStrom@jourrapide.com",
          type: "E-mail",
          isFavorite: true
        },
      ],
      emailAux: "WhilmaStrom@jourrapide.com",
      jobrole: "Telecommunications equipment repairer",
      company: "Brand Names",
      companyEmails: [
        {
          address: "WhilmaStrom@brandnames.com",
          type: "Principal",
          isFavorite: true
        }
      ],
      companyPhones: [
        {
          number: "0019-5731091",
          type: "Principal",
          isFavorite: true
        }
        
      ],
      companyAddresses: [
        {
          number: 505,
          street: "Bond Street",
          city: "Hayes",
          state: "Kansas",
          stateAbbreviation: "KS",
          zipcode: 36678,
          isFavorite: false
        },
      ],
      phones: [
        {
          number: "0155-2998905",
          type: "E-mail",
          isFavorite: true
        },
      ],
      addresses: [
        {
          number: 991,
          street: "Melba Court",
          city: "Gila",
          state: "Mississippi",
          stateAbbreviation: "MS",
          zipcode: 48248,
          isFavorite: false
        },
        {
          number: 848,
          street: "Canda Avenue",
          city: "Fillmore",
          state: "American Samoa",
          stateAbbreviation: "AS",
          zipcode: 73409,
          isFavorite: false
        },
        {
          number: 554,
          street: "Chestnut Avenue",
          city: "Wheaton",
          state: "Texas",
          stateAbbreviation: "TX",
          zipcode: 21393,
          isFavorite: true
        }
      ]
    }
  ];

  private institutionContactsDummy = [];
  
  constructor(
    private activatedRoute: ActivatedRoute, 
    private accountService: AccountService) { }

  ngOnInit() {
    this.contactsAux = [...this.dummyContactsList];

    this.activatedRoute.paramMap.subscribe(params => {
      if( params.has('companyId') ) {
        console.log(' | Company | -- Instance');
        this.accountService.setAccountType({
          companyId: params.get('companyId'),
          type: 'company'
        });

      } else {
        console.log(' | Account | -- Instance');
        this.accountService.setAccountType({ 
          type: 'account' 
        });
        
      }
    });
  }

  onContactSelect(contact) {
    this.selectedContact = contact;
    this.showComments = true;
  }

  closeComments(toggle: boolean) {
    this.showComments = toggle;
  }

  onOrientationSelected(event) {
    this.selectedOrientation = event;
  }
  
  onSearchContact(event) {
    const result = this.contactsAux.filter( d => {
      return (
        d.name.toLowerCase().indexOf(event) !== -1 || 
        d.company.toLowerCase().indexOf(event) !== -1 || 
        d.phones[0].number.toLowerCase().indexOf(event) !== -1 || 
        d.companyPhones[0].number.toLowerCase().indexOf(event) !== -1 || 
        d.emails[0].address.toLowerCase().indexOf(event) !== -1 || 
        d.companyEmails[0].address.toLowerCase().indexOf(event) !== -1 || 
        !event)
    });

    this.dummyContactsList = result;
  }

  onChangeDataType ( option ): void {
    if ( option === 'person' ) {
      this.dummyContactsList = this.personContactsDummy;

    } else if ( option === 'institution' ) {
      this.dummyContactsList = this.institutionContactsDummy;

    } else {
      console.log('else')
    }

    this.contactsAux = [...this.dummyContactsList];
    this.selectedContact = '';
  }
}
