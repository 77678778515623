import { Component, OnInit, ElementRef, Output, EventEmitter, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'contact-actions',
  templateUrl: './contact-actions.component.html',
  styleUrls: ['./contact-actions.component.scss']
})
export class AccountContactActionsComponent implements OnInit {
  @ViewChild('inputSearchContact', { static: true }) inputSearchContact: ElementRef;
  @Output() contactsOrientationEmit: EventEmitter<string> = new EventEmitter();
  @Output() searchContactEmit: EventEmitter<string> = new EventEmitter();
  @Output() dataTypeEmit: EventEmitter<string> = new EventEmitter();
  @Input() searchContact: string;

  private dataType: string = 'person';
  private selectedOrientation: string = "details";


  constructor() { }

  ngOnInit() {
    this.dataTypeEmit.emit(this.dataType);
  }

  // private onSearch(search: string):void {
  //   if ( search.length > 0 ) {
  //     console.log(search);
  //   } else {
  //     console.log('nothing');
  //   }
  // }

  // public onKeypress(e) {
  //   if ( e.keyCode === 13 || e.charCode === 13 || e.key === "Enter") {
  //     const query = this.inputSearchContact.nativeElement.value;

  //     this.onSearch(query);
  //   }
  // }

  public changeOrientation( type: string ) {
    if ( type === 'details' ) {
      this.selectedOrientation = 'listview';
      this.contactsOrientationEmit.emit('listview');

    } else {
      this.selectedOrientation = 'details'
      this.contactsOrientationEmit.emit('details');

    } 
  }

  public updateFilter(event) {
    const val = event.target.value.toLowerCase();

    this.searchContactEmit.emit(val);    
  }

  public changeDataFormat ( dataType: string ) {
    this.searchContact = ''
    this.dataType = dataType;
    this.dataTypeEmit.emit(this.dataType);
  }
}
