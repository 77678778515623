import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridMenuRenderer } from './grid-components/grid-menu.component';
import { Router, NavigationExtras } from '@angular/router';

interface Contact {
  _id: string,
  contact: string,
  company: string,
  mainEmailPersonal: string,
  mainPhonePersonal: string,
  mainEmailProfessional: string,
  mainPhoneProfessional: string,
  mainAddressPersonal: string,
  mainAddressProfessional: string,
  birthday: string,
  document: string
}

@Component({
  selector: 'app-contacts-listview',
  templateUrl: './contacts-listview.component.html',
  styleUrls: ['./contacts-listview.component.scss']
})
export class AccountContactsListviewComponent implements OnInit, OnChanges {
  @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;
  @Input() contactsList: any;
  private columnDefs: any;
  private allColumns: any;

  rowData = [];
  
  constructor( private router: Router ) { 
    this.columnDefs = [
      {
        sortingOrder: 0,
        field: "selectCol",
        width: 50,
        maxWidth: 50,
        minWidth: 50,
        checkboxSelection: true, 
        headerCheckboxSelection: true,
        hide: false,
        lockPosition: true,
        cellStyle: { 'background-color': 'var(--main-bg-color);', 'padding-bottom': '3px;' },
      },
      {
        sortingOrder: 1,
        headerName: "Contato",
        field: "contact",
        maxWidth: 300,
        filter: true,
        resizable: true,
        hide: false,
        cellClass: "ag-custom-cell",
        cellStyle: { 'font-weight': '700' },
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      },
      {
        sortingOrder: 2,
        headerName: "Empresa", 
        field: "company", 
        maxWidth: 200,
        filter: true, 
        resizable: true,
        hide: false,
        cellClass: "ag-custom-cell",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 3,
        headerName: "Telefone Pessoal", 
        field: 'mainPhonePersonal',
        maxWidth: 170,
        minWidth: 100,
        filter: true, 
        resizable: true,
        hide: false,
        cellClass: "ag-custom-cell",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 4,
        headerName: "E-mail", 
        field: 'mainEmailPersonal', 
        maxWidth: 300,
        filter: true, 
        resizable: true,
        hide: false,
        cellClass: "ag-custom-cell",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 5,
        headerName: "Telefone Profissional", 
        field: 'mainPhoneProfessional',
        maxWidth: 180,
        minWidth: 100,
        filter: true, 
        resizable: true,
        hide: false,
        cellClass: "ag-custom-cell",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 6,
        headerName: "Email Profissional", 
        field: 'mainEmailProfessional', 
        filter: true, 
        resizable: true,
        hide: true,
        cellClass: "ag-custom-cell",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 7,
        headerName: "Endereço", 
        field: 'mainAddressPersonal',
        width: 200,
        filter: true, 
        resizable: true,
        hide: false,
        cellClass: "ag-custom-cell cell-address",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 8,
        headerName: "Endereço Profissional", 
        field: 'mainAddressProfessional',
        width: 200,
        filter: true, 
        resizable: true,
        hide: true,
        cellClass: "ag-custom-cell cell-address",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 9,
        headerName: "Data de Nascimento", 
        field: 'birthday', 
        filter: true,
        resizable: true,
        hide: true,
        cellClass: "ag-custom-cell",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      }, 
      {
        sortingOrder: 10,
        headerName: "CPF", 
        field: 'document', 
        filter: true, 
        resizable: true,
        hide: true,
        cellClass: "ag-custom-cell",
        cellRenderer: function(params) {
          return `${params.value}`;
        }
      },
      // {
      //   sortingOrder: 11,
      //   maxWidth: 60,
      //   filter: false,
      //   resizable: false,
      //   hide: false,
      //   pinned: 'right',
      //   cellRenderer: function ( params ) {
      //     let eDiv = document.createElement('div');
      //         eDiv.className = "my-css-class";
      //         eDiv.innerHTML = `<a class="btn-simple">
      //                         <img src="../../../../../assets/client/icons/icon-grid-menu.png" alt="">
      //                       </a>`;
      //     let eButton = eDiv.querySelectorAll('.btn-simple')[0];
      
      //     eButton.addEventListener('click', function() {
      //       console.log(params)
      //     });
      
      //     return eDiv;
      //   }
      // },
    ];


    this.frameworkComponents = {
      gridMenuRenderer: GridMenuRenderer
    }
  }

  ngOnInit() {
    this.allColumns = this.columnDefs.slice(1);
    
    if ( this.contactsList ) {
      this.contactsList.map( el => {
        let fullAddressPersonal = `${el.addresses[0].city} ${el.addresses[0].stateAbbreviation} ${el.addresses[0].street} ${el.addresses[0].number} ${el.addresses[0].zipcode}`
        let fullAddressProfessional = `${el.companyAddresses[0].city} ${el.companyAddresses[0].stateAbbreviation} ${el.companyAddresses[0].street} ${el.companyAddresses[0].number} ${el.companyAddresses[0].zipcode}`

        let contact: Contact = {
          '_id': el.id,
          'contact': el.name,
          'company': el.company,
          'mainEmailPersonal': el.emails[0].address,
          'mainPhonePersonal': el.phones[0].number,
          'mainEmailProfessional': el.companyEmails[0].address,
          'mainPhoneProfessional': el.companyPhones[0].number,
          'mainAddressPersonal': fullAddressPersonal,
          'mainAddressProfessional': fullAddressProfessional,
          'birthday': '13/11/1980',
          'document': '123.456.123-01'
        }

        this.rowData.push(contact);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let change = changes["contactsList"];
    console.log(change)

    this.rowData = [];

    if ( change.currentValue ) {
      change.currentValue.map( el => {
        let fullAddressPersonal = `${el.addresses[0].city} ${el.addresses[0].stateAbbreviation} ${el.addresses[0].street} ${el.addresses[0].number} ${el.addresses[0].zipcode}`
        let fullAddressProfessional = `${el.companyAddresses[0].city} ${el.companyAddresses[0].stateAbbreviation} ${el.companyAddresses[0].street} ${el.companyAddresses[0].number} ${el.companyAddresses[0].zipcode}`

        let contact: Contact = {
          '_id': el.id,
          'contact': el.name,
          'company': el.company,
          'mainEmailPersonal': el.emails[0].address,
          'mainPhonePersonal': el.phones[0].number,
          'mainEmailProfessional': el.companyEmails[0].address,
          'mainPhoneProfessional': el.companyPhones[0].number,
          'mainAddressPersonal': fullAddressPersonal,
          'mainAddressProfessional': fullAddressProfessional,
          'birthday': '13/11/1980',
          'document': '123.456.123-01'
        };

        this.rowData.push(contact);
      });
    }
  }

  public onGridReady( params ) {
    // console.log(this.columnDefs)
    this.allColumns.splice(-1, 1);
    
    const hideColumns = [
      'mainEmailProfessional',
      'mainAddressProfessional',
      'birthday',
      'document'
    ];
    
    this.agGrid.columnApi.setColumnsVisible(hideColumns, false);

    let columnsState = this.agGrid.columnApi.getColumnState();

    columnsState.map( state => {
      this.allColumns.filter( column => {
          if ( state.colId === column.field && state.hide ) return column.hide = true
      })
    });

    this.agGrid.api.sizeColumnsToFit();
  }

  public columnToggle(col) {
    const isChecked = this.isChecked(col);

    console.log(isChecked)
    console.log(col.hide)
    console.log(col.field)

    let colField: string = col.field;
    let colVisibility: boolean = col.hide;

    this.agGrid.columnApi.setColumnVisible(colField, isChecked);

    // console.log(this.agGrid.columnApi.getColumnState());

    if (isChecked) {
      this.columnDefs = this.columnDefs.filter(c => {
        return c.field !== col.field;
      });

    } else {
      this.columnDefs = [...this.columnDefs, col];
      this.columnDefs = this.columnDefs.sort((a, b) => a.sortingOrder - b.sortingOrder);
      
    }

    setTimeout(() => {
      this.agGrid.columnApi.resetColumnState();
      this.agGrid.api.sizeColumnsToFit();
    }, 10)

    // let state = this.agGrid.columnApi.getColumnState();
    // this.agGrid.columnApi.setColumnState(state);
    
  }

  public isChecked(col) {
    return (
      this.columnDefs.find(c => {
        return c.field === col.field;
      }) !== undefined
    );
  }


  // AG GRID CONTEXT____________________________________

  private frameworkComponents;
  private gridMenu = `
      <button (click)="gridContextMenu(this)">
          <span class="sr-only">Edit</span>
      </button>`;

      
  onRowClicked(e) { 

  }

  
  onCellClicked(e) {
    if ( e.column.colId != "selectCol" ) {
      const navigationExtras: NavigationExtras = {
        state: { data: e.data }
      };
  
      this.router.navigate([`./client/contact/edit/${e.data._id}`], navigationExtras );
    }
  }


  getSelectedRows() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map( node => node.data );

    console.log(selectedData)

    const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ');
  }

  refreshData() {
    
    this.agGrid.api.sizeColumnsToFit();
    // this.agGrid.columnApi.resetColumnState();

    // 'mainEmailProfessional',
    //   'mainAddressProfessional',
    //   'birthday',
    //   'document'
  
    
    this.agGrid.columnApi.setColumnVisible('birthday', true);

  }
  
  // end: AG GRID CONTEXT____________________________________

}
