import { Route } from '@angular/router';

import { ClientAuthGuard } from '../../core/guard/client-auth.guard';

import { ClientComponent } from './client.component';
import { AccountOverviewComponent } from './account/account-overview/overview.component';
import { TeamRoutes } from './company/team/team.routes';
import { AccountRoutes } from './account/account.routes';
import { CompanyRoutes } from './company/company.routes';
// import { ContactComponent } from './contact/contact.component';
// import { ContactEditComponent } from './contact/contact-edit/contact-edit.component';
import { EventsComponent } from './events/events.component';
import { FontScaleComponent } from './test/font-scale/font-scale.component';


/* 
  -- Main Route
    Esta deverá ser a rota principal do sistema pois, caso a sessão do usuário ainda esteja ativa, o mesmo não deverá ser direcionado para ao login
    Caso o usuário ainda não esteja logado/autenticado (AuthGuard), o mesmo deverá ser redirecionado para a tela de login
*/

export const ClientRoutes: Route[] = [
  {
    path: '',
    component: ClientComponent,
    // canActivate: [ClientAuthGuard],
    
    children: [
      {
        path: 'client/account',
        children: [...AccountRoutes],
        canActivateChild: [ClientAuthGuard]
      },
      {
        path: 'client/company',
        children: [...CompanyRoutes],
        canActivateChild: [ClientAuthGuard]
      },
      // {
      //   path: 'client/company/create',
      //   component: CompanyCreateComponent
      // },
      // {
      //   path: 'client/company/:id/events',
      //   component: EventsComponent
      // },
      // {
      //   path: 'client/company/edit/:id',
      //   loadChildren: () =>
      //     import('./company/company-edit/edit.module').then(m => m.CompanyEditModule)
      // },
      // {
      //   path: 'client/company/overview',
      //   loadChildren: () =>
      //     import('./company/company-overview/overview.module').then(m => m.CompanyOverviewModule)
      // },
      // {
      //   path: 'client/company/overview/:id',
      //   loadChildren: () =>
      //     import('./company/company-overview/overview.module').then(m => m.CompanyOverviewModule)
      // },
      // {
      //   path: 'client/company/create',
      //   component: CompanyCreateComponent
      // },
      // {
      //   path: 'client/company/exists',
      //   component: CompanyExistsComponent
      // },
      // {
      //   path: 'client/company/recover',
      //   component: CompanyRecoverComponent
      // },
      // {
      //   path: 'client/team',
      //   children: [...TeamRoutes]
      // },
      // {
      //   path: 'client/contact',
      //   component: ContactComponent
      // },
      // {
      //   path: 'client/:company/contact',
      //   component: ContactComponent
      // },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'client/account/incompleted'
      },
      {
        path: 'client',
        pathMatch: 'full',
        redirectTo: 'client/account/incompleted'
      },
      
      //   {
      //     path: 'user/:username',
      //     loadChildren: () =>
      //       import('../home/user/user.module').then(m => m.UserModule)
      //   },
      //   {
      //     path: 'user/:username/:id',
      //     loadChildren: () =>
      //       import('../home/user/user.module').then(m => m.UserModule)
      //   }
      {
        path: 'client/font-scale',
        component: FontScaleComponent
      }
    ]
  }
];
