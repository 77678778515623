import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from 'src/app/_services/auth/auth.service';
import { CompanyService } from 'src/app/_services/company/company.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Subscription } from 'rxjs';
import { CompanyExistsModule } from '../../../account/company-create/company-exists/exists.module';

@Component({
  selector: 'company-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class CompanyContactEditComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private contactForm: FormGroup;
  private professionalContactForm: FormGroup;
  private currentUserData: any;
  private submitted: boolean = false;
  private allCountries: any;
  private countries: any = [];
  private states$: any = [];
  private cities$: any = [];
  private selectedCompanyId: string;
  private selectedContactId: string;
  public formFormat: string;
  public addContactCompanyModalOpen: boolean = false;
  public addAddressCompanyModalOpen: boolean = false;
  public addEmailCompanyModalOpen: boolean = false;
  public addPhoneCompanyModalOpen: boolean = false;
  public addRelationshipCompanyModalOpen: boolean = false;
  public companyAddresses: any;
  public companyEmails: any;
  public companyPhones: any;

  private title = {
    main: "Editar Contato"
  }
  
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private companyService: CompanyService
  ) { }


  ngOnInit() {
    this.currentUserData = history.state.data;
    console.log(this.currentUserData)

    const url = this.router.url.split('/');
    const lastSegment = this.getLastUrlSegment(url);

    // pensar em uma solução genérica
    if ( lastSegment === "new" ) {
      this.title.main = "Novo Contato - Pessoa Física";
    }

    this.subscriptions.push(
      this.companyService.observeCorporateUser
        .subscribe( el => this.selectedCompanyId = el )
    );
    
    if( !this.selectedCompanyId ) 
      this.selectedCompanyId = localStorage.getItem('selected-company-id');

    this.contactForm = this.formBuilder.group({
      // ___Personal Data
      _id: [ '' ],
      fullName: [ '', [ Validators.required, Validators.maxLength(255)]],
      cpf: [ '', [ Validators.minLength(11), Validators.maxLength(14) ]],
      rg: [ '', [ Validators.minLength(9), Validators.maxLength(12) ]],
      birthday: [ '' , [ Validators.minLength(8), Validators.maxLength(10) ]],
      passport: [ '', Validators.maxLength(9) ],
      passportExpirationDate: [ '' ],
      title: [ '' ],
      
      emailMulti: this.formBuilder.array([]),
      phoneMulti: this.formBuilder.array([]),
      addressMulti: this.formBuilder.array([])
    });


    // this.authService.getRelationshipContactCompany()
    //   subscribe();
    /*
      {
        "institutionId": 11,
        "position": "cargo",
        "address": {
          "id": 13,
          "label": "Teste 5",
          "postalCode": "81280-440",
          "line1": "Teste line1",
          "line2": "Teste line2",
          "line3": "Teste line3",
          "cityId": 3712
        },
        "phones": [
          {
            "id": 17,
            "label": "Celular",
            "number": "41996873931",
            "countryCode": "BR",
            "main": true
          }
        ],
        "emails": [
          {
            "id": 14,
            "label": "Pessoal",
            "email": "teste@it4cio.com",
            "main": true
          }
        ],
        "coworkers": [
          {
            "personId": 12,
            "name": "teste",
            "position": "cargo"
          }
        ]
      }
    */

    
  }

  ngAfterViewInit() { 
    this.selectedContactId = this.route.snapshot.paramMap.get('contactId');

    if( this.selectedContactId ) {
      this.subscriptions.push(
        this.authService.getCompanyContactPerson( this.selectedCompanyId, this.selectedContactId )
          .subscribe(
            data => this.populateForm( data ),
            error => console.log(error)
          )
        );

    } else {
      this.populateForm( undefined );
    }

    this.subscriptions.push(
      this.authService.getCountries()
        .subscribe(
          countries => this.allCountries = countries,
          error => { console.log(error) }
        )
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subscription => subscription.unsubscribe());
  }

  
  /**
   * START
   * Formulário - Dados Pessoais
   */
  private populateForm( data ) {
    if( data ) {
      this.contactForm.patchValue({
        _id: data.id,
        image: data.imageUrl,
        fullName: data.name,
        cpf: data.privateIndividualDocument,
        rg: data.identificationCard,
        birthday: data.birthday ? this.convertDatePtBr(data.birthday) : '',
        title: data.title,
      });
  
      if ( data.emails && data.emails.length > 0 ) 
        this.populateFormMulti( data.emails, 'email' );
      else 
        this.addEmailInput( true );
        
      if ( data.phones && data.phones.length > 0 ) 
        this.populateFormMulti( data.phones, 'phone' );
      else 
        this.addPhoneInput( true );
      
      if ( data.addresses && data.addresses.length > 0 ) 
        this.populateFormMulti( data.addresses, 'address' );
      else
        this.addAddressInput( true );
      
    } else {
      this.addEmailInput( true );
      this.addPhoneInput( true );
      this.addAddressInput( true );
    }
  }

  private populateFormMulti( data, type ) {
    let index = 0;
    data.sort( (a, b) => b.main - a.main );
  
    data.forEach( el => {
      switch( type ) {
        case 'email': 
          this.addEmailInput( undefined, el );
          break;
          
        case 'phone': 
          this.addPhoneInput( undefined, el );
          break;
          
        case 'address': {
          let countryCode = el.countryCode;
          let stateCode = el.stateCode;
  
          this.setStatesByCode( countryCode, index );
          this.setCitiesByCode( stateCode, countryCode, index );

          this.addAddressInput( undefined, el );

          index++;
          break;
        }
      }
    })
  }

  get f() { return this.contactForm.controls; }
  get emailMulti() { return this.contactForm.get('emailMulti') as FormArray; }
  get phoneMulti() { return this.contactForm.get('phoneMulti') as FormArray; }
  get addressMulti() { return this.contactForm.get('addressMulti') as FormArray; }


  // this.professionalContactForm = this.formBuilder.group({
  //   institutionId: [ '' ],
  //   position: [ '' ],
  //   professionalAddress: this.formBuilder.group({
  //     id: [ '' ],
  //     label: [ '' ],
  //     postalCode: [ '' ],
  //     line1: [ '' ],
  //     line2: [ '' ],
  //     line3: [ '' ],
  //     cityId: [ '' ]
  //   }),
  //   professionalPhones: this.formBuilder.array([]),
  //   professionalEmails: this.formBuilder.array([]),
  //   professionalCoworkers: this.formBuilder.array([])
  // });

  private populateProfessionalForm( data ) {
    if( data ) {
      console.log(data)
      this.professionalContactForm.patchValue({
        // institutionId: data.institutionId,
        // position: data.position,
        // professionalAddress: this.formBuilder.group({
        //   id: data.id,
        //   label: data.label,
        //   postalCode: data.postalCode,
        //   line1: data.line1,
        //   line2: data.line2,
        //   line3: data.line3,
        //   cityId: data.cityId
        // })
      });

      console.log(this.professionalContactForm)
  
      if ( data.emails && data.emails.length > 0 ) 
        this.populateProfessionalFormMulti( data.emails, 'email' );
      else 
        // this.addEmailInput( true );
        console.log(123)

      if ( data.phones && data.phones.length > 0 ) 
        this.populateProfessionalFormMulti( data.phones, 'phone' );
      else 
        // this.addPhoneInput( true );
        console.log(123)
      
      if ( data.coworkers && data.coworkers.length > 0 ) 
        this.populateProfessionalFormMulti( data.coworkers, 'coworker' );
      else
        // this.addAddressInput( true );
        console.log(123)
    } 
  }

  private populateProfessionalFormMulti( data, type ) {
    data.sort( (a, b) => b.main - a.main );
  
    data.forEach( el => {
      switch( type ) {
        case 'email': 
          this.addProfessionalEmailInput( el ); break;
          
        case 'phone': 
          this.addProfessionalPhoneInput( el ); break;
          
        case 'coworker': {
          this.addCoworkerProfessionalInput( el ); break;
        }
      }
    });
  }


  get professionalEmailsMulti() { return this.professionalContactForm.get('professionalEmailsMulti') as FormArray; }
  get professionalPhonesMulti() { return this.professionalContactForm.get('professionalPhonesMulti') as FormArray; }
  get professionalCoworkersMulti() { return this.professionalContactForm.get('professionalCoworkersMulti') as FormArray; }


  public addProfessionalEmailInput( email: any ): void {
    let control = this.formBuilder.group({
      id: email.id,
      label: email.label,
      email: email.email,
      main: email.main,
    });

    this.professionalEmailsMulti.push(control);
  }

  public addProfessionalPhoneInput( phone: any ): void {
    let control = this.formBuilder.group({
      id: phone.id,
      label: phone.label,
      number: phone.number,
      countryCode: phone.countryCode,
      main: phone.main
    });

    this.professionalPhonesMulti.push(control);
  }

  public addCoworkerProfessionalInput( coworker: any ): void {
    let control = this.formBuilder.group({
      personId: coworker.personId,
      name: coworker.name,
      position: coworker.position
    });

    this.professionalCoworkersMulti.push(control);
  }
  



  public addEmailInput( isMain?: boolean, emailData?: any ): void {
    let control;

    if ( emailData ) {
      control = this.formBuilder.group({
        id: emailData.id,
        label: emailData.label,
        email: emailData.email,
        main: emailData.main,
        pendingConfirmation: emailData.pendingConfirmation
      });

    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        email: [ '', [ Validators.email, Validators.maxLength(255) ]],
        main: isMain ? isMain : [ '' ],
        pendingConfirmation: [ '' ]
      });
    }

    this.emailMulti.push(control);
  }

  public addPhoneInput( isMain?: boolean, phoneData?: any ): void {
    let control;
    
    if ( phoneData ) {
      control = this.formBuilder.group({
        id: phoneData.id,
        label: phoneData.label,
        number: phoneData.number,
        countryCode: phoneData.countryCode,
        main: phoneData.main
      });

    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        number: [ '' ],
        countryCode: [ '' ],
        main: isMain ? isMain : [ '' ]
      });
    }

    this.phoneMulti.push(control);
  }

  public addAddressInput( isMain?: boolean, addressData?: any ): void {
    let control;

    if ( addressData ) {
      control = this.formBuilder.group({
        id: addressData.id,
        label: addressData.label,
        postalCode: addressData.postalCode,
        countryCode: addressData.countryCode,
        countryName: addressData.countryName,
        stateCode: addressData.stateCode,
        stateName: addressData.stateName,
        cityId: addressData.cityId,
        cityName: addressData.cityName,
        line1: addressData.line1,
        line2: addressData.line2,
        line3: addressData.line3,
        main: addressData.main
      });
      
    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        postalCode: [ '' ],
        countryCode: [ '' ],
        countryName: [ '' ],
        stateCode: [ '' ],
        stateName: [ '' ],
        cityId: [ '' ],
        cityName: [ '' ],
        line1: [ '' ],
        line2: [ '' ],
        line3: [ '' ],
        main: isMain ? isMain : [ '' ]
      });
    }

    this.addressMulti.push(control);
  }


  public removeEmailInput(index: number): void {
    this.emailMulti.removeAt(index);
  }

  public removePhoneInput(index: number): void {
    this.phoneMulti.removeAt(index);
  }

  public removeAddressInput(index: number): void {
    this.addressMulti.removeAt(index);
  }


  public favouriteEmail( emailSelected: any ): void {
    let allEmails = this.emailMulti.controls;

    allEmails.forEach( email => {
      email.value.main = false;
    });

    emailSelected.value.main = true;
  }

  public favouritePhone( phoneSelected: any ): void {
    let allPhones = this.phoneMulti.controls;

    allPhones.forEach( phone => {
      phone.value.main = false;
    });

    phoneSelected.value.main = true;
  }

  public favouriteAddress( addressSelected: any ): void {
    let allAddresses = this.addressMulti.controls;

    allAddresses.forEach( address => {
      address.value.main = false;
    });

    addressSelected.value.main = true;
  }


  private setStatesByCode( countryCode: string, index: number ) {
    this.subscriptions.push(
      this.authService.getStatesByCode(countryCode)
        .subscribe(
          data => { this.states$[index] = data },
          error => { console.log(error) }
        )
    );
  }

  private setCitiesByCode( stateCode: string, countryCode: string, index: number ): void {
    this.subscriptions.push(
      this.authService.getCitiesByCode(stateCode, countryCode)
        .subscribe(
          data => { this.cities$[index] = data },
          error => { console.log(error) }
        )
    );
  }


  public onCountryChange( address_: any, index: number ): void {
    const countryCode = address_.value.countryCode;
    this.countries[index] = countryCode;

    address_.controls['cityId'].setValue('');
    address_.controls['stateCode'].setValue('');

    this.setStatesByCode( countryCode, index );
  }

  public onStateChange( address_: any, index ): void {
    const stateCode = address_.value.stateCode;
    const countryCode = address_.value.countryCode;

    this.setCitiesByCode( stateCode, countryCode, index );
  }
  
  
  public onPhoneType( countryCode: any, phone_: any ) {
    let phone = phone_.value.number;
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode)

    if( phoneNumber && phoneNumber.formatNational() !== undefined ) {
      phone = phoneNumber.formatNational();
      phone_.controls['number'].setValue(phone);
    } 
  }

  private convertFormValues( contactForm ) {
    let formValues = contactForm.value;

    if( formValues.emailMulti.length === 1 && !formValues.emailMulti[0].label && !formValues.emailMulti[0].email ) 
      formValues.emailMulti = null;

    if( formValues.phoneMulti.length === 1 && !formValues.phoneMulti[0].label && !formValues.phoneMulti[0].countryCode && !formValues.phoneMulti[0].number ) 
      formValues.phoneMulti = null;

    if( formValues.addressMulti.length === 1 && !formValues.addressMulti[0].label && !formValues.addressMulti[0].countryCode && !formValues.addressMulti[0].stateCode && !formValues.addressMulti[0].cityId ) 
      formValues.addressMulti = null;

    if( !formValues.cpf )
      formValues.cpf = null;


    return {
      "id": formValues._id,
      "name": formValues.fullName,
      "privateIndividualDocument": formValues.cpf,
      "identificationCard": formValues.rg,
      "birthday": this.convertDateUTC(formValues.birthday),
      "title": formValues.title,
      "emails": formValues.emailMulti,
      "addresses": formValues.addressMulti,
      "phones": formValues.phoneMulti,
      "languageCode": "pt-br"
    }
  }

  /**
   * END
   * Formulário - Dados Pessoais
   */

  public favouriteProfessionalEmail( emailSelected: any ): void {
    let allEmails = this.professionalEmailsMulti.controls;

    allEmails.forEach( email => {
      email.value.main = false;
    });

    emailSelected.value.main = true;
  }

  public favouriteProfessionalPhone( phoneSelected: any ): void {
    let allPhones = this.professionalPhonesMulti.controls;

    allPhones.forEach( phone => {
      phone.value.main = false;
    });

    phoneSelected.value.main = true;
  }

  private professionalConvertFormValues( contactForm ) {
    let formValues = contactForm.value;

    // {
    //   "institutionId": 11,
    //   "position": "cargo",
    //   "address": {
    //     "id": 13,
    //     "label": "Teste 5",
    //     "postalCode": "81280-440",
    //     "line1": "Teste line1",
    //     "line2": "Teste line2",
    //     "line3": "Teste line3",
    //     "cityId": 3712
    //   },
    //   "phones": [
    //     {
    //       "id": 17,
    //       "label": "Celular",
    //       "number": "41996873931",
    //       "countryCode": "BR",
    //       "main": true
    //     }
    //   ],
    //   "emails": [
    //     {
    //       "id": 14,
    //       "label": "Pessoal",
    //       "email": "teste@it4cio.com",
    //       "main": true
    //     }
    //   ],
    //   "coworkers": [
    //     {
    //       "personId": 12,
    //       "name": "teste",
    //       "position": "cargo"
    //     }
    //   ]
    
  }


  public onChangeContactFormat( format: string ) {
    this.formFormat = format;
  }

  public addContactCompanyModal() {
    this.addContactCompanyModalOpen = true;
  }
  public addContactCompanyModalClose() {
    this.addContactCompanyModalOpen = false;
  }

  public addAddressCompanyModal() {
    this.addAddressCompanyModalOpen = true;
  }
  public addAddressCompanyModalClose() {
    this.addAddressCompanyModalOpen = false;
  }

  public addEmailCompanyModal() {
    this.addEmailCompanyModalOpen = true;
  }
  public addEmailCompanyModalClose() {
    this.addEmailCompanyModalOpen = false;
  }

  public addPhoneCompanyModal() {
    this.addPhoneCompanyModalOpen = true;
  }
  public addPhoneCompanyModalClose() {
    this.addPhoneCompanyModalOpen = false;
  }

  public addRelationshipCompanyModal() {
    this.addRelationshipCompanyModalOpen = true;
  }
  public addRelationshipCompanyModalClose() {
    this.addRelationshipCompanyModalOpen = false;
  }


  private getLastUrlSegment(route): string {
    var parts = this.router.url.split('/');
    var lastSegment = parts.pop() || parts.pop();
    
    return lastSegment;
  }

  private convertDatePtBr( date ) {
    const dateObj = new Date(date + 'T00:00:00');
    return new Intl.DateTimeFormat('pt-BR').format(dateObj);
  }

  private convertDateUTC( date ) {
    if ( date ) {
      let newDate = date.split('/');
      return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

    } else {
      return '';

    }
  }

  // implementação para toggle do accordion
  public onCompanyToggle(event) {
    const paths = ( event as any ).path as HTMLElement[];

    let companySection = paths[3];

    // árvore de elementos do bubble / propagation
    console.log(paths[3])
    if( companySection.classList.contains('company-bound-info') && !companySection.classList.contains('company-hidden') ) {
      companySection.classList.add('company-hidden');
    }
    else {
      companySection.classList.remove('company-hidden');
    }

  }

  public onCompanyDelete(event) {
    alert("Company to be deleted...")    

  }


  public onSubmitPersonForm() {
    this.submitted = true;
    console.log(this.contactForm);

    if( this.contactForm.invalid ) {
      return;
      
    } else {
      const formValues = this.convertFormValues( this.contactForm );
      const contactId = this.contactForm.value._id;

      if( !contactId ) {
        this.subscriptions.push(
          this.authService.createCompanyContactPerson( formValues, this.selectedCompanyId )
            .subscribe(
              data => { 
                this.contactForm.patchValue({ 
                  _id: data.id,
                });
                // Ao cadastrar Novo Contato, redirecionar para página anterior
                // this.location.back();
                this.router.navigate(['../../../contact/'], { queryParams: { "ref": data.id, "format": 'person' }, relativeTo: this.route });
              },
              error => { console.log(error); }
          )
        );
        
      } else {
        this.subscriptions.push(
          this.authService.updateCompanyContactPerson( formValues, this.selectedCompanyId )
            .subscribe(
              data => { 
                this.router.navigate(['../../../contact/'], { queryParams: { "ref": data.id, "format": 'person' }, relativeTo: this.route });
              },
              error => { console.log(error); }
            )
        );
      }
    }
    console.log(this.contactForm);

    this.submitted = false;
  }


  public onAddCompanyModal( selectedCompany ) {
    console.log(selectedCompany)

    this.professionalContactForm = this.formBuilder.group({
      institutionId: [ selectedCompany.institutionId ],
      tradingName: [ selectedCompany.tradingName ],
      position: [ selectedCompany.position ],
      professionalAddress: this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        postalCode: [ '' ],
        line1: [ '' ],
        line2: [ '' ],
        line3: [ '' ],
        cityName: [ '' ],
        countryName: [ '' ],
        stateName: [ '' ],
      }), 
      
      professionalPhonesMulti: this.formBuilder.array([]),
      professionalEmailsMulti: this.formBuilder.array([]),
      professionalCoworkersMulti: this.formBuilder.array([])
    });

    this.subscriptions.push(
      this.authService.getCompanyContactInstitution( this.selectedCompanyId, selectedCompany.institutionId )
        .subscribe(
          data => {
            setTimeout(() => {
              this.companyAddresses = data.addresses.length > 0 ? data.addresses : null;
              this.companyEmails = data.emails.length > 0 ? data.emails : null;
              this.companyPhones = data.phones.length > 0 ? data.phones : null;
              console.log("this.companyAddresses: " + this.companyAddresses )
              console.log("this.companyEmails: " + this.companyEmails )
              console.log("this.companyPhones: " + this.companyPhones )
              this.populateProfessionalForm( data );
            }, 500);
          },
          error => console.log(error)
        )
      );
  }

  public onAddAddressCompanyModal( companyAddress ) {
    this.professionalContactForm.controls
      .professionalAddress.patchValue({
        id: companyAddress.id,
        label: companyAddress.label,
        postalCode: companyAddress.postalCode,
        line1: companyAddress.line1,
        line2: companyAddress.line2,
        line3: companyAddress.line3,
        cityName: companyAddress.cityName,
        countryName: companyAddress.countryName,
        stateName: companyAddress.stateName
      });
    
    console.log(this.professionalContactForm.value.professionalAddress)
  }

  public onAddRelationshipCompanyModal( contact ) {
    console.log(contact)

  }
}
