import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { SharedService } from 'src/app/core/shared/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyCreateComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private id: any;
  private isSubmitting: boolean = false;
  private companyRecoverModal: boolean = false;
  private companyExistsModal: boolean = false;
  private isCnpjInvalid: boolean = false;
  private corpData;
  private userData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => { 
      this.id = params.get('id');
      // this.sharedService.setCorporateUser(this.id);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subscription => subscription.unsubscribe());
  }

  companyRecoverModalToggle(event) {
    this.companyRecoverModal = !this.companyRecoverModal;
    event ? this.companyExistsModal = false : '' ;
  }

  companyExistsModalToggle() {
    this.companyExistsModal = !this.companyExistsModal;
  }

  onclickme() {
    this.router.navigate(['client/company/1/events/']);
    // this.router.navigate(['../../../contact/'], { queryParams: { "ref": data.id, "format": 'person' }, relativeTo: this.route });
  }

  public onSubmit( formValues: any, appForm: any) {
    this.isSubmitting = true;
    
    if ( appForm.invalid ) {
      this.isSubmitting = false;
      return;
    }


    this.userData = {
      "id": null,
      "name": formValues.companyName,
      "tradingName": formValues.tradingName,
      "document": formValues.cnpj,
      "corporateEmail": formValues.companyEmail,
      "phone": formValues.companyPhone,
    }

    this.subscriptions.push(
      this.authService.createCompany( this.userData )
        .subscribe(
          data => { 
            alert('Conta corporativa cadastrada com sucesso!');

            setTimeout(() => {
              this.router.navigate([`client/company/${data.id}/events/`]);
              this.isSubmitting = false;
            }, 1000);
          },
          error => {
            this.isCnpjInvalid = false;
            console.log(error);
            console.log(error.error.fieldErrors[0].message);
            if ( error.error ) {
              if ( error.error.fieldErrors[0].message === "COMPANY_DOCUMENT_ALREADY_IN_USE" ) {
                this.corpData = {
                  "companyDocument": this.userData.companyDocument,
                  "email": this.userData.corporateEmail
                } 
                this.companyExistsModalToggle();

              } 
              
              /** NÃO FUNCIONANDO
               *  o valor da variável isCnpjInvalid não sofre alteração */
              else if ( error.error.fieldErrors[0].message.indexOf("CNPJ inválido") !== -1 ) {
                this.isCnpjInvalid = true;
              }
            }

            // this.alertService.error(error);
          },
          () => { 
            appForm.reset();
            this.isSubmitting = false 
          }
        )
    );
  }
}
