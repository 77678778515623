import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AccountService {
  public accountType = new BehaviorSubject('');

  private sharedSubjectAccountType = new BehaviorSubject<any>('');
  observeAccountType: Observable<any>;

  constructor() {
    this.observeAccountType = this.sharedSubjectAccountType.asObservable();
  }

  setAccountType( data: any ) {
      this.sharedSubjectAccountType.next(data);
  }
}