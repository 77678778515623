import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'contact-company-relationship',
  templateUrl: './relationship.component.html',
  styleUrls: ['./relationship.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'contactCompanyRelationshipModal'}
})
export class ContactCompanyRelationshipAddModalComponent implements OnInit, OnDestroy {
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onAddRelationshipCompanyEmit = new EventEmitter<any>();
  @Input() modalRelationshipCompanyOpen: boolean;
  @Input("companyAddresses") companyAddresses: any;
  @Input("companyId") companyId: any;
  
  private subscriptions: Subscription[] = [];
  private coworkerSelectionForm: FormGroup;
  private companyContacts: any;
  private newCoworkerForm: FormGroup;
  private isNewContact: boolean = false;
  private modalWrapper: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService) {}

  ngOnInit() {
    this.modalWrapper = document.getElementById('contactCompanyRelationshipModal') as HTMLElement;
    this.modalWrapper.addEventListener('click', (e) => {
      this.onModalClose();
    });

    this.subscriptions.push(
      this.authService.getCompanyContactsPerson( this.companyId )
      .subscribe( 
        contacts => {
          console.log(contacts)
          this.companyContacts = contacts;
        },
        err => console.log(err)
      )
    )

    this.coworkerSelectionForm = this.formBuilder.group({
      id: [ '' ],
      coworker: [ '', Validators.required ],
    })

    this.newCoworkerForm = this.formBuilder.group({
      id: [ '' ],
      name: [ '' ],
      email: [ '' ],
      phone: [ '' ],
      position: [ '' ]
    });

    console.log(this.companyAddresses)
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subscription => subscription.unsubscribe());
  }

  public onNotAllowed() {
    alert("Em construção!");
  }


  public modalStopPropagation( $event ) {
    $event.stopPropagation();
  }

  public onModalClose() {
    this.onClose.emit( !this.modalRelationshipCompanyOpen );
  }


  get f() { return this.coworkerSelectionForm.controls; }


  private convertFormValues( form ) {
    let formValues = form.value;
    
    return {
      "id": formValues.id,
      "label": formValues.label,
      "postalCode": formValues.postalCode,
      "line1": formValues.line1,
      "line2": formValues.line2,
      "line3": formValues.line3,
      "cityName": formValues.cityName,
      "countryName": formValues.countryName,
      "stateName": formValues.stateName
    }
  }


  public onSubmitCompanyRelationship() {
    let selectedAddress;
    
    if( this.coworkerSelectionForm.invalid ) { return }
    
    // selectedAddress = this.companyAddresses.filter( el => {
    //   return el.id == this.coworkerSelectionForm.value.coworker;
    // })[0];

    console.log(selectedAddress)

    if( selectedAddress ) {
      this.newCoworkerForm.patchValue({
        id: selectedAddress.id,
        label: selectedAddress.label,
        postalCode: selectedAddress.postalCode,
        line1: selectedAddress.line1,
        line2: selectedAddress.line2,
        line3: selectedAddress.line3,
        cityName: selectedAddress.cityName,
        countryName: selectedAddress.countryName,
        stateName: selectedAddress.stateName
      });

      const formValues = this.convertFormValues( this.newCoworkerForm );

      this.onAddRelationshipCompanyEmit.emit( formValues );

      setTimeout(() => {
        this.onModalClose();
      }, 1000);
    }
  }
}
