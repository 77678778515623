import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-company-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'companyRecoverModal'}
})
export class CompanyRecoverComponent implements OnInit {
  @Output() onClose = new EventEmitter<boolean>();
  @Input() companyRecoverOpen: boolean;

  ngOnInit() { }

  onModalClose() {
    this.onClose.emit( !this.companyRecoverOpen );
  }
  
  modalStopPropagation = ($event) => {
    $event.stopPropagation();
  }
}
