import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'company-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'contactCompanyAddressesModal'}
})
export class ContactCompanyAddressesModalComponent implements OnInit {
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onAddAddressCompanyEmit = new EventEmitter<any>();
  @Input() modalAddressesCompanyOpen: boolean;
  @Input("companyAddresses") companyAddresses: any;

  private addressInstitutionIdForm: FormGroup;
  private institutionAddressForm: FormGroup;
  private selectedAddressId: number;
  private modalWrapper: any;
  
  constructor(
    private formBuilder: FormBuilder) {}

  ngOnInit() { 
    this.modalWrapper = document.getElementById('contactCompanyAddressesModal') as HTMLElement;
    this.modalWrapper.addEventListener('click', (e) => {
      this.onModalClose();
    });

    this.addressInstitutionIdForm = this.formBuilder.group({
      addressInstitution: [ '' ]
    })

    this.institutionAddressForm = this.formBuilder.group({
      id: [ '' ],
      label: [ '' ],
      postalCode: [ '' ],
      line1: [ '' ],
      line2: [ '' ],
      line3: [ '' ],
      cityName: [ '' ],
      countryName: [ '' ],
      stateName: [ '' ]
    });

    console.log(this.companyAddresses)

    // cityId: 3701
    // cityName: "Altamira do Paraná"
    // countryCode: "BR"
    // countryName: "Brasil"
    // id: 26
    // label: "Residencia"
    // line1: "Avenida Salvadores Amados"
    // line2: "Ap. 282"
    // line3: ""
    // main: true
    // postalCode: "80060600"
    // stateCode: "18"
    // stateName: "Paraná"
  }

  public modalStopPropagation( $event ) {
    $event.stopPropagation();
  }

  public onModalClose() {
    this.onClose.emit( !this.modalAddressesCompanyOpen );
  }

  private convertFormValues( form ) {
    let formValues = form.value;
    
    return {
      "id": formValues.id,
      "label": formValues.label,
      "postalCode": formValues.postalCode,
      "line1": formValues.line1,
      "line2": formValues.line2,
      "line3": formValues.line3,
      "cityName": formValues.cityName,
      "countryName": formValues.countryName,
      "stateName": formValues.stateName
    }
  }

  public onAddressChange( address: number ): void {
    this.selectedAddressId = address;
  }


  public onSubmitCompanyAddress() {
    let selectedAddress;
    
    if( this.addressInstitutionIdForm.invalid ) { return }
    
    selectedAddress = this.companyAddresses.filter( el => {
      return el.id == this.addressInstitutionIdForm.value.addressInstitution;
    })[0];

    console.log(selectedAddress)

    if( selectedAddress ) {
      this.institutionAddressForm.patchValue({
        id: selectedAddress.id,
        label: selectedAddress.label,
        postalCode: selectedAddress.postalCode,
        line1: selectedAddress.line1,
        line2: selectedAddress.line2,
        line3: selectedAddress.line3,
        cityName: selectedAddress.cityName,
        countryName: selectedAddress.countryName,
        stateName: selectedAddress.stateName
      });

      const formValues = this.convertFormValues( this.institutionAddressForm );

      this.onAddAddressCompanyEmit.emit( formValues );

      setTimeout(() => {
        this.onModalClose();
      }, 1000);
    }
  }
}
