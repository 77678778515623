import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'contact-company-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'id': 'contactCompanyAddModal'}
})
export class ContactCompanyAddModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onAddCompanyEmit = new EventEmitter<any>();
  @Input() modalContactCompanyOpen: boolean;
  @Input() contactId: string;
  @Input() companyId: string;
  
  private subscriptions: Subscription[] = [];
  private addCompanyForm: FormGroup;
  private companies: any;
  private modalWrapper: any;
  private submitting: boolean = false;
  private selectedCompanyId: string;
  private subscription: Subscription[] = [];
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
    ) { }

  ngOnInit() { 
    this.modalWrapper = document.getElementById('contactCompanyAddModal') as HTMLElement;
    this.modalWrapper.addEventListener('click', (e) => {
      this.onModalClose();
    });

    this.addCompanyForm = this.formBuilder.group({
      _id: [ '' ],
      companyData: [ '', Validators.required ],
      position: [ '', [ Validators.required, Validators.maxLength(255)]],
    });

    this.selectedCompanyId = localStorage.getItem('selected-company-id');
  }

  ngAfterViewInit() {
    this.subscription.push(
      this.authService.getCompanyContactsInstitution( this.selectedCompanyId )
        .subscribe( contacts => {
          this.companies = contacts;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.forEach( subscription => subscription.unsubscribe() );
  }

  get f() { return this.addCompanyForm.controls; }


  public modalStopPropagation( $event ) {
    $event.stopPropagation();
  }

  public onModalClose() {
    this.onClose.emit( !this.modalContactCompanyOpen );
  }

  private convertFormValues( form ) {
    let formValues = form.value;

    return {
      "id": formValues._id,
      "tradingName": formValues.companyData.tradingName,
      "institutionId": formValues.companyData.id,
      "position": formValues.position,
    }
  }


  public onSubmitCompanyAdd() {
    let selectedInstitution;

    if( this.addCompanyForm.invalid ) { return }

    this.submitting = true;

    const formValues = this.convertFormValues( this.addCompanyForm );

    console.log(formValues)

    this.subscriptions.push(
      this.authService.getCompanyContactInstitution( this.selectedCompanyId, formValues.institutionId )
        .subscribe( 
          company => {
            setTimeout(() => {
              selectedInstitution = company;

              console.log("formValues: "+ formValues);
              console.log("this.companyId: "+ this.companyId);
              console.log("this.contactId: "+ this.contactId);

              // formData, companyId, personId
              this.subscription.push(
                this.authService.createRelationshipContactCompany( formValues, this.companyId, this.contactId)
                  .subscribe( 
                    data => {
                      console.log(data)
                    },
                    err => console.log(err),
                    () => {
                      alert(3411412)
                    }
                  )
              );

            }, 500);
          },
          err => console.log(err),
          () => {
            this.onModalClose();
            this.submitting = false;
          }
        )
      );

    this.onAddCompanyEmit.emit( formValues );
  }
}
