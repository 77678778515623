import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SharedService } from 'src/app/core/shared/shared.service';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'app-company-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit {
  private companyId: any;
  private company: any;
  private ownerAccounts: any[] = [];
  private addOwnerOpen: boolean = false;
  private errorSubmit: boolean = false;
  private isSubmitting: boolean = false;

  private owners = [
    {
      "id": 0,
      "isActive": true,
      "invitePending" : false,
      "picture": "http://placehold.it/32x32",
      "name": "Heidi Alvarado",
      "role": "Technical Manager",
      "companyId": 10
    },
    {
      "id": 1,
      "isActive": true,
      "invitePending" : false,
      "picture": "http://placehold.it/32x32",
      "name": "Pate Tyson",
      "role": "Account Owner",
      "companyId": 10
    },
    {
      "id": 2,
      "isActive": false,
      "invitePending" : true,
      "picture": "http://placehold.it/32x32",
      "name": "Jessica Hayden",
      "role": "Administrator",
      "companyId": 10
    },
    {
      "id": 3,
      "isActive": false,
      "invitePending" : false,
      "picture": "http://placehold.it/32x32",
      "name": "Reed Mcintosh",
      "role": "Administrator",
      "companyId": 10
    },
    {
      "id": 4,
      "isActive": true,
      "invitePending" : false,
      "picture": "http://placehold.it/32x32",
      "name": "Avery Dudley",
      "role": "Technical Manager",
      "companyId": 11
    },
    {
      "id": 5,
      "isActive": true,
      "invitePending" : false,
      "picture": "http://placehold.it/32x32",
      "name": "Brandi Shaffer",
      "role": "Administrator",
      "companyId": 11
    },
    {
      "id": 6,
      "isActive": false,
      "invitePending" : true,
      "picture": "http://placehold.it/32x32",
      "name": "Parks Cameron",
      "role": "Administrator",
      "companyId": 11
    },
    {
      "id": 7,
      "isActive": true,
      "invitePending" : false,
      "picture": "http://placehold.it/32x32",
      "name": "Debbie Roberts",
      "role": "Account Owner",
      "companyId": 19
    },
    {
      "id": 8,
      "isActive": false,
      "invitePending" : true,
      "picture": "http://placehold.it/32x32",
      "name": "Diaz Hawkins",
      "role": "Technical Manager",
      "companyId": 19
    },
    {
      "id": 9,
      "isActive": false,
      "invitePending" : false,
      "picture": "http://placehold.it/32x32",
      "name": "Joanne Noble",
      "role": "Technical Manager",
      "companyId": 19
    },
    {
      "id": 10,
      "isActive": false,
      "invitePending" : true,
      "picture": "http://placehold.it/32x32",
      "name": "Allen Anthony",
      "role": "Administrator",
      "companyId": 19
    }
  ];

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private sharedService: SharedService,
    private authService: AuthService,
  ) {
    // update the DOM when navigating to the same router link
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    };
  }
    
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => { 
      this.companyId = params.get('companyId');
      // this.sharedService.setCorporateUser(this.companyId);
    });

    // setting corporate user manually by shared service
    // this.sharedService.setCorporateUser('1337');

    this.authService.getCompaniesById(this.companyId)
      .subscribe(
        data => {
          this.showCompanyInfo(data);

          this.owners.map( el => {
            el.companyId === data.id ? this.ownerAccounts.push(el) : null;
          })
        },
        error => { console.log(error); }
      );
  }


  // getLastUrlSegment(route): string {
  //   var parts = this.router.url.split('/');
  //   var lastSegment = parts.pop() || parts.pop();
    
  //   return lastSegment;
  // }

  showCompanyInfo( data ) {
    this.company = {
      "id": data.id,
      "image": data.image,
      "razao": data.name,
      "segment": data.name,
      "document": data.document,
      "about": "",
      "phones": data.phone,
      "emails": data.corporateEmail,
      "addresses": [
        // {
        //   "number": 991,
        //   "street": "Av. Lorem Ipsum dolor sit amet, 000",
        //   "city": "Curitiba",
        //   "state": "Paraná",
        //   "stateAbbreviation": "MS",
        //   "zipcode": "80000 - 000",
        //   "isFavorite": true
        // },
        // {
        //   "number": 848,
        //   "street": "Av. Lorem Ipsum dolor sit amet, 000",
        //   "city": "Curitiba",
        //   "state": "Paraná",
        //   "stateAbbreviation": "AS",
        //   "zipcode": "80000 - 000",
        //   "isFavorite": false
        // },
        // {
        //   "number": 554,
        //   "street": "Av. Lorem Ipsum dolor sit amet, 000",
        //   "city": "Curitiba",
        //   "state": "Paraná",
        //   "stateAbbreviation": "TX",
        //   "zipcode": "80000 - 000",
        //   "isFavorite": false
        // }
      ]
    }
  }

  onAddClick = () => {
    this.addOwnerOpen = true;
  }

  onModalClose = () => {
    this.addOwnerOpen = false;
  }

  removeAdmin = ( event, id ) => {
    event.target.parentNode.parentNode.parentNode.parentNode.remove();
  }
}
