import { Component } from '@angular/core';
import { AuthService } from './_services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private authService: AuthService) {}
  
  onPostTest() {
    // console.log(this.authService.register());
  }
  
  // onUpdateTest() {
  //   console.log(this.authService.update());
  // }
  
  // onDeleteTest() {
  //   console.log(this.authService.delete());
  // }
  
}
