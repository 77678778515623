import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/_services/auth/auth.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js'

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class AccountContactEditComponent implements OnInit, AfterViewInit {
  private contactForm: FormGroup;
  private currentUserData: any;
  private submitted: boolean = false;
  private allCountries: any;
  private countries: any = [];
  private states$: any = [];
  private cities$: any = [];

  private title = {
    main: "Editar Contato"
  }
  
  constructor(
    private router: Router, 
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }


  ngOnInit() {
    this.currentUserData = history.state.data;
    console.log(this.currentUserData)

    const url = this.router.url.split('/');
    const lastSegment = this.getLastUrlSegment(url);

    // pensar em uma solução genérica
    if ( lastSegment === "new" ) {
      this.title.main = "Novo Contato";
    }

    this.contactForm = this.formBuilder.group({
      // ___Personal Data
      _id: [ '' ],
      fullName: [ '', [ Validators.required, Validators.maxLength(255)]],
      cpf: [ '', [ Validators.minLength(11), Validators.maxLength(14) ]],
      rg: [ '', [ Validators.minLength(9), Validators.maxLength(12) ]],
      birthday: [ '' , [ Validators.minLength(8), Validators.maxLength(10) ]],
      passport: [ '', Validators.maxLength(9) ],
      passportExpirationDate: [ '' ],
      title: [ '' ],
      
      emailMulti: this.formBuilder.array([]),
      phoneMulti: this.formBuilder.array([]),
      addressMulti: this.formBuilder.array([]),
    });
  }

  ngAfterViewInit() { 
    this.authService.getCountries()
      .subscribe(
        countries => this.allCountries = countries,
        error => { console.log(error) }
      );

    this.fillProfileForm( '' );
  }

  private fillProfileForm( userData ) {
    if( userData ) {
      this.contactForm.patchValue({
        _id: userData.id,
        image: userData.imageUrl,
        fullName: userData.name,
        cpf: userData.privateIndividualDocument,
        rg: userData.identificationCard,
        birthday: userData.birthday ? this.convertDatePtBr(userData.birthday) : '',
        title: userData.title,
      });
  
      // // Emails Data 
      if ( userData.emails.length > 0 ) {
        userData.emails.sort( (a, b) => b.main - a.main );
  
        userData.emails.forEach( email => {
          this.addEmailInput(undefined, email);
        })
  
      } else {
        this.addEmailInput( true );
      }

      // Phones Data
      if ( userData.phones.length > 0 ) {
        userData.phones.sort( (a, b) => b.main - a.main );

        userData.phones.forEach( phone => {
          this.addPhoneInput( undefined, phone );
        });

      } else {
        this.addPhoneInput( true );
      }
  
      // Addresses Data
      if ( userData.addresses.length > 0 ) {
        userData.addresses.sort( (a, b) => b.main - a.main );

        userData.addresses.forEach( address => {
          this.addAddressInput( undefined, address );
        });

      } else {
        this.addAddressInput( true );
      }

    } else {
        this.addEmailInput( true );
        this.addPhoneInput( true );
        this.addAddressInput( true );
    }
  }

  get f() { return this.contactForm.controls; }
  get emailMulti() { return this.contactForm.get('emailMulti') as FormArray; }
  get phoneMulti() { return this.contactForm.get('phoneMulti') as FormArray; }
  get addressMulti() { return this.contactForm.get('addressMulti') as FormArray; }

  public addEmailInput( isMain?: boolean, emailData?: any ): void {
    let control;

    if ( emailData ) {
      control = this.formBuilder.group({
        id: emailData.id,
        label: emailData.label,
        email: emailData.email,
        main: emailData.main,
        pendingConfirmation: emailData.pendingConfirmation
      });

    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' , [ Validators.required ]],
        email: [ '', [ Validators.required, Validators.email, Validators.maxLength(255) ]],
        main: isMain ? isMain : [ '' ],
        pendingConfirmation: [ '' ]
      });
    }

    this.emailMulti.push(control);
  }

  public addPhoneInput( isMain?: boolean, phoneData?: any ): void {
    let control;
    
    if ( phoneData ) {
      control = this.formBuilder.group({
        id: phoneData.id,
        label: phoneData.email,
        number: phoneData.label,
        countryCode: phoneData.countryCode,
        main: phoneData.main
      });

    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '' ],
        number: [ '' ],
        countryCode: [ '' ],
        main: isMain ? isMain : [ '' ]
      });
    }

    this.phoneMulti.push(control);
  }

  public addAddressInput( isMain?: boolean, addressData?: any ): void {
    let control;

    if ( addressData ) {
      control = this.formBuilder.group({
        id: addressData.id,
        label: addressData.label,
        postalCode: addressData.postalCode,
        countryCode: addressData.countryCode,
        countryName: addressData.countryName,
        stateCode: addressData.stateCode,
        stateName: addressData.stateName,
        cityId: addressData.cityId,
        cityName: addressData.cityName,
        line1: addressData.line1,
        line2: addressData.line2,
        line3: addressData.line3,
        main: addressData.main
      });
      
    } else {
      control = this.formBuilder.group({
        id: [ '' ],
        label: [ '', [ Validators.required ]],
        postalCode: [ '' ],
        countryCode: [ '' ],
        countryName: [ '' ],
        stateCode: [ '' ],
        stateName: [ '' ],
        cityId: [ '', [ Validators.required ]],
        cityName: [ '' ],
        line1: [ '' ],
        line2: [ '' ],
        line3: [ '' ],
        main: isMain ? isMain : [ '' ]
      });
      
    }

    this.addressMulti.push(control);
  }


  public removeEmailInput(index: number): void {
    this.emailMulti.removeAt(index);
  }

  public removePhoneInput(index: number): void {
    this.phoneMulti.removeAt(index);
  }

  public removeAddressInput(index: number): void {
    this.addressMulti.removeAt(index);
  }


  public favouriteEmail( emailSelected: any ): void {
    let allEmails = this.emailMulti.controls;

    allEmails.forEach( address => {
      address.value.main = false;
    });

    emailSelected.value.main = true;
  }

  public favouritePhone( phoneSelected: any ): void {
    let allPhones = this.phoneMulti.controls;

    allPhones.forEach( phone => {
      phone.value.main = false;
    });

    phoneSelected.value.main = true;
  }

  public favouriteAddress( addressSelected: any ): void {
    let allAddresses = this.addressMulti.controls;

    allAddresses.forEach( address => {
      address.value.main = false;
    });

    addressSelected.value.main = true;
  }


  private setStatesByCode( countryCode: string, index: number ) {
    this.authService.getStatesByCode(countryCode)
      .subscribe(
        data => { this.states$[index] = data },
        error => { console.log(error) }
      )
  }

  private setCitiesByCode( stateCode: string, countryCode: string, index: number ): void {
    this.authService.getCitiesByCode(stateCode, countryCode)
      .subscribe(
        data => { this.cities$[index] = data },
        error => { console.log(error) }
      )
  }


  public onCountryChange( address_: any, index: number ): void {
    const countryCode = address_.value.countryCode;
    this.countries[index] = countryCode;

    address_.controls['cityId'].setValue('');
    address_.controls['stateCode'].setValue('');

    this.setStatesByCode( countryCode, index );
  }

  public onStateChange( address_: any, index ): void {
    const stateCode = address_.value.stateCode;
    const countryCode = address_.value.countryCode;

    this.setCitiesByCode( stateCode, countryCode, index );
  }
  
  
  public onPhoneType( countryCode: any, phone_: any ) {
    let phone = phone_.value.number;
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode)

    if( phoneNumber && phoneNumber.formatNational() !== undefined ) {
      phone = phoneNumber.formatNational();
      phone_.controls['number'].setValue(phone);
    } 
  }


  private getLastUrlSegment(route): string {
    var parts = this.router.url.split('/');
    var lastSegment = parts.pop() || parts.pop();
    
    return lastSegment;
  }

  private convertDatePtBr( date ) {
    const dateObj = new Date(date + 'T00:00:00');
    return new Intl.DateTimeFormat('pt-BR').format(dateObj);
  }

  private convertDateUTC( date ) {
    if ( date ) {
      let newDate = date.split('/');
      return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

    } else {
      return '';

    }
  }

  onSubmit = () => {
    this.submitted = true;
    console.log(this.contactForm);

    if(this.contactForm.invalid) {
      return;
      
    } else {
      // const formValues = this.convertFormValues(this.contactForm.value);

      // this.authService.updateCurrentUser( this.contactForm )
      //   .subscribe(
      //     data => { console.log(data) },  
      //     error => { console.log(error) }
      //   )

      console.log(this.contactForm);
    }
  }


}
